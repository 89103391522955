import * as THREE from "three";
import { Box2, MeshStandardMaterial, Vector3 } from "three";

const _plane = new THREE.Mesh(
  new THREE.PlaneGeometry(10, 10),
  new MeshStandardMaterial()
);

const _raycaster = new THREE.Raycaster();

const _pointer = new THREE.Vector2();

const _offset = new THREE.Vector3();

const _intersection = new THREE.Vector3();

const _worldPosition = new THREE.Vector3();

const _inverseMatrix = new THREE.Matrix4();

class DragControls extends THREE.EventDispatcher {
  safeArea;

  constructor(_objects, _camera, _domElement, _safeArea, _isSelected) {
    super();
    _domElement.style.touchAction = "none"; // disable touch scroll

    this.safeArea = _safeArea;

    const scene = _camera.parent;
    scene.add(_plane);
    _plane.visible = false;
    // _plane.setRotationFromEuler(new THREE.Euler(0, 0, 0));

    let _selected = null,
      _hovered = null;
    const _intersections = []; //

    const scope = this;

    this.objects = _objects;

    this.artworkPosition = new THREE.Vector3();
    this.artworkRotation = new THREE.Euler();

    function updatePlane(self) {
      _plane.position.copy(self.artworkPosition);
      _plane.rotation.copy(self.artworkRotation);
    }

    function activate(self) {
      _domElement.addEventListener("pointermove", onPointerMove.bind(self));

      _domElement.addEventListener("pointerdown", onPointerDown.bind(self));

      _domElement.addEventListener("pointerup", onPointerCancel.bind(self));

      _domElement.addEventListener("pointerleave", onPointerCancel.bind(self));
    }

    function deactivate() {
      _domElement.removeEventListener("pointermove", onPointerMove.bind(self));

      _domElement.removeEventListener("pointerdown", onPointerDown.bind(self));

      _domElement.removeEventListener("pointerup", onPointerCancel.bind(self));

      _domElement.removeEventListener(
        "pointerleave",
        onPointerCancel.bind(self)
      );

      _domElement.style.cursor = "";
    }

    function dispose() {
      deactivate();
    }

    function getObjects() {
      return scope.objects;
    }

    function getRaycaster() {
      return _raycaster;
    }

    function onPointerMove(event) {
      if (scope.enabled === false) return;
      updatePointer(event);

      _raycaster.setFromCamera(_pointer, _camera);

      if (_selected) {
        const hits = _raycaster.intersectObject(_plane, false);
        if (hits.length > 0) {
          _intersection.copy(hits[0].point);

          const proposedPosition = _intersection
            .sub(_offset)
            .applyMatrix4(_inverseMatrix);

          const selectedNodeBox = new Box2();
          selectedNodeBox.setFromCenterAndSize(
            proposedPosition,
            _selected.totalSize()
          );

          if (this.safeArea) {
            clampBoxInBox(selectedNodeBox, this.safeArea);
            let newCenter = new THREE.Vector2();
            selectedNodeBox.getCenter(newCenter);
            _selected.rotation.copy(_plane.rotation);
            _selected.position.copy(
              new THREE.Vector3(newCenter.x, newCenter.y, 0)
            );
          } else {
            _selected.rotation.copy(_plane.rotation);
            _selected.position.copy(proposedPosition);
          }
        }

        // if (_raycaster.ray.intersectBox(_box, _intersection)) {
        //   _selected.position.copy(
        //     _intersection.sub(_offset).applyMatrix4(_inverseMatrix)
        //   );
        // }

        scope.dispatchEvent({
          type: "drag",
          object: _selected,
        });
        return;
      } // hover support

      if (event.pointerType === "mouse" || event.pointerType === "pen") {
        _intersections.length = 0;

        _raycaster.setFromCamera(_pointer, _camera);

        _raycaster.intersectObjects(scope.objects, true, _intersections);

        if (_intersections.length > 0) {
          const object = _intersections[0].object;

          if (_hovered !== object && _hovered !== null) {
            scope.dispatchEvent({
              type: "hoveroff",
              object: _hovered,
            });
            _domElement.style.cursor = "auto";
            _hovered = null;
          }

          if (_hovered !== object) {
            scope.dispatchEvent({
              type: "hoveron",
              object: object,
            });
            _domElement.style.cursor = "pointer";
            _hovered = object;
          }
        } else {
          if (_hovered !== null) {
            scope.dispatchEvent({
              type: "hoveroff",
              object: _hovered,
            });
            _domElement.style.cursor = "auto";
            _hovered = null;
          }
        }
      }
    }

    function onPointerDown(event) {
      updatePlane(this);

      if (scope.enabled === false) return;
      updatePointer(event);
      _intersections.length = 0;

      _raycaster.setFromCamera(_pointer, _camera);

      _raycaster.intersectObjects(scope.objects, true, _intersections);

      if (_intersections.length > 0) {
        _selected =
          scope.transformGroup === true
            ? scope.objects[0]
            : _intersections[0].object;
        _isSelected(!!_selected);

        const hits = _raycaster.intersectObject(_plane, false);
        if (hits.length > 0) {
          _inverseMatrix.copy(_selected.parent.matrixWorld).invert();

          _intersection.copy(hits[0].point);

          _offset
            .copy(_intersection)
            .sub(_worldPosition.setFromMatrixPosition(_selected.matrixWorld));
        }

        // if (_raycaster.ray.intersectBox(_box, _intersection)) {
        //   _inverseMatrix.copy(_selected.parent.matrixWorld).invert();

        //   _offset
        //     .copy(_intersection)
        //     .sub(_worldPosition.setFromMatrixPosition(_selected.matrixWorld));
        // }

        _domElement.style.cursor = "move";
        scope.dispatchEvent({
          type: "dragstart",
          object: _selected,
        });
      }
    }

    function onPointerCancel() {
      if (scope.enabled === false) return;

      if (_selected) {
        scope.dispatchEvent({
          type: "dragend",
          object: _selected,
        });
        _selected = null;
        _isSelected(false);
      }

      _domElement.style.cursor = _hovered ? "pointer" : "auto";
    }

    function updatePointer(event) {
      const rect = _domElement.getBoundingClientRect();

      _pointer.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      _pointer.y = (-(event.clientY - rect.top) / rect.height) * 2 + 1;
    }

    activate(this);

    this.enabled = true;
    this.transformGroup = true;
    this.activate = activate;
    this.deactivate = deactivate;
    this.dispose = dispose;
    this.getObjects = getObjects;
    this.getRaycaster = getRaycaster;
    this.updatePlane = updatePlane;
  }
}

function clampBoxInBox(target, bounds) {
  /*
  Given two boxes, fit the target box within the bounding box with the shortest distance.
  */

  let boundsSize = new THREE.Vector2();
  bounds.getSize(boundsSize);

  let targetSize = new THREE.Vector2();
  target.getSize(targetSize);

  if (targetSize.x < boundsSize.x && targetSize.y < boundsSize.y) {
    // Consider each axis separately.
    // X axis
    let translation = new THREE.Vector2(0, 0);
    if (target.min.x < bounds.min.x) {
      translation.x = bounds.min.x - target.min.x;
    } else if (target.max.x > bounds.max.x) {
      translation.x = bounds.max.x - target.max.x;
    }
    if (target.min.y < bounds.min.y) {
      translation.y = bounds.min.y - target.min.y;
    } else if (target.max.y > bounds.max.y) {
      translation.y = bounds.max.y - target.max.y;
    }
    target.translate(translation);
    return true;
  } else {
    let center = new THREE.Vector2();
    bounds.getCenter(center);
    target.setFromCenterAndSize(center, targetSize);
  }

  return false;
}

export { DragControls, clampBoxInBox };
