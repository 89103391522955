import React, { useState, useEffect, useRef } from "react";

import { Starboard /*, ContentMode , Configuration*/ } from "starboard";

import { getPalette } from "react-palette";
import Color from "colorjs.io";

//@ts-ignore
import { authenticatedFetch, publishImage, metric } from "./APIUtils.ts";

//@ts-ignore
import SettingsPanel from "./SettingsPanel.tsx";

import "@shopify/polaris/build/esm/styles.css";
import {
  LegacyCard as Card,
  Button,
  Modal,
  TextContainer,
  Text,
  LegacyStack as Stack,
  Toast,
} from "@shopify/polaris";
import { RefreshIcon } from "@shopify/polaris-icons";

import Compressor from "compressorjs";
import { CustomView, deviceType } from "react-device-detect";

const scenes = [
  {
    id: "blank-white-70",
    backgroundTexture: "textures/blank-white.jpg",
    backgroundThumbnail: "textures/blank-white-70_thumbnail.jpg",
    cameraFov: 30,
    artworkOnlyMode: true,
    artworkOnlyCanvasFillRatio: 0.7,

    cameraPosition: {
      x: 0,
      y: 0,
      z: 3,
    },
    cameraRotation: {
      x: 0,
      y: 0,
      z: 0,
    },
    artworkPosition: {
      x: 0,
      y: 0,
      z: 0,
    },
    artworkRotation: { x: 0, y: 0, z: 0 },
  },
  {
    id: "blank-white-angled-70",
    backgroundTexture: "textures/blank-white.jpg",
    backgroundThumbnail: "textures/blank-white-angled-70_thumbnail.jpg",
    cameraFov: 30,
    artworkOnlyMode: true,
    artworkOnlyCanvasFillRatio: 0.7,

    cameraPosition: {
      x: 0,
      y: 0,
      z: 3,
    },
    cameraRotation: {
      x: 0,
      y: 0,
      z: 0,
    },
    artworkPosition: {
      x: 0,
      y: 0,
      z: 0,
    },
    artworkRotation: { x: 0, y: -0.7, z: 0 },
  },
  {
    id: "blank-gray-70",
    backgroundTexture: "textures/blank-gray.jpg",
    backgroundThumbnail: "textures/blank-gray-70_thumbnail.jpg",
    cameraFov: 30,
    artworkOnlyMode: true,
    artworkOnlyCanvasFillRatio: 0.7,

    cameraPosition: {
      x: 0,
      y: 0,
      z: 3,
    },
    cameraRotation: {
      x: 0,
      y: 0,
      z: 0,
    },
    artworkPosition: {
      x: 0,
      y: 0,
      z: 0,
    },
    artworkRotation: { x: 0, y: 0, z: 0 },
  },
  {
    id: "blank-gray-angled-70",
    backgroundTexture: "textures/blank-gray.jpg",
    backgroundThumbnail: "textures/blank-gray-angled-70_thumbnail.jpg",
    cameraFov: 30,
    artworkOnlyMode: true,
    artworkOnlyCanvasFillRatio: 0.7,

    cameraPosition: {
      x: 0,
      y: 0,
      z: 3,
    },
    cameraRotation: {
      x: 0,
      y: 0,
      z: 0,
    },
    artworkPosition: {
      x: 0,
      y: 0,
      z: 0,
    },
    artworkRotation: { x: 0, y: -0.7, z: 0 },
  },
  {
    id: "dw-scene-02c_004",
    backgroundTexture: "textures/dw-scene-02c_004.jpg",
    backgroundThumbnail: "textures/dw-scene-02c_004_thumbnail.jpg",
    cameraFov: 39.5978,
    focalColor: "#DACCBC",

    cameraPosition: {
      x: 0.9803496599197388,
      y: 1.1669756174087524,
      z: 0.4367722570896149,
    },
    cameraRotation: {
      x: -0.0000906325294636,
      y: 1.5734152793884277,
      z: -0.0000912726027309,
    },
    artworkPosition: {
      x: -4.4173040390014648,
      y: 1.6244051456451416,
      z: 0.608400821685791,
    },
    artworkRotation: { x: -0.0, y: 1.5707962512969971, z: 0.0 },
  },
  {
    id: "dw-scene-06",
    name: "dw-scene-06",
    backgroundTexture: "textures/dw-scene-06_001.jpg",
    backgroundThumbnail: "textures/dw-scene-06_001_thumbnail.jpg",
    cameraFov: 39.1462516608203899,
    cameraPosition: {
      x: 3.6428623199462891,
      y: 0.9349159002304077,
      z: -2.2904667854309082,
    },
    cameraRotation: { x: 0.0000000437113918, y: -0.0080207046121359, z: -0.0 },
    artworkPosition: {
      x: 3.6477346420288086,
      y: 1.2134265899658203,
      z: -5.2923445701599121,
    },
    artworkRotation: { x: 0.0, y: 0.0000001192092896, z: 0.0 },
  },
  {
    id: "dw-scene-02",
    name: "dw-scene-02",
    backgroundTexture: "textures/dw-scene-07_001.jpg",
    backgroundThumbnail: "textures/dw-scene-07_001_thumbnail.jpg",
    cameraFov: 46.7704421144275102,
    cameraPosition: {
      x: 3.6428623199462891,
      y: 1.5132107734680176,
      z: -2.7225954532623291,
    },
    cameraRotation: { x: 0.0000000437113918, y: -0.0080207046121359, z: -0.0 },
    artworkPosition: {
      x: 3.6477346420288086,
      y: 1.6,
      z: -5.2923445701599121,
    },
    artworkRotation: { x: 0.0, y: 0.0000001192092896, z: 0.0 },
  },
  {
    id: "dw-scene-07_001",
    backgroundTexture: "textures/dw-scene-07v2_001.jpg",
    backgroundThumbnail: "textures/dw-scene-07v2_001_thumbnail.jpg",
    focalColor: "#DACCBC",

    cameraFov: 46.7704421144275102,
    cameraPosition: {
      x: 3.6428623199462891,
      y: 1.3499572277069092,
      z: -3.5470688343048096,
    },
    cameraRotation: { x: 0.0000000437113883, y: 0.0000001748455531, z: -0.0 },
    artworkPosition: {
      x: 3.6477346420288086,
      y: 1.5036861896514893,
      z: -5.2923445701599121,
    },
    artworkRotation: { x: 0.0, y: 0.0000001192092896, z: 0.0 },
  },
  {
    id: "dw-scene-03",
    name: "dw-scene-03",
    backgroundTexture: "textures/dw-scene-08_001.jpg",
    backgroundThumbnail: "textures/dw-scene-08_001_thumbnail.jpg",
    focalColor: "#8E291D",
    cameraFov: 46.7704421144275102,
    cameraPosition: {
      x: 5.768855094909668,
      y: 1.5132107734680176,
      z: 0.4958743751049042,
    },
    cameraRotation: { x: 0.0000000437113918, y: 3.1335721015930176, z: 0.0 },
    artworkPosition: {
      x: 5.7591972351074219,
      y: 1.5036861896514893,
      z: 2.5595452785491943,
    },
    artworkRotation: { x: 0.0, y: 3.1415, z: 0.0 },
  },
  {
    id: "dw-scene-08v2",
    backgroundTexture: "textures/dw-scene-08v2_002.jpg",
    backgroundThumbnail: "textures/dw-scene-08v2_002_thumbnail.jpg",
    focalColor: "#DACCBC",

    cameraFov: 46.7704421144275102,
    cameraPosition: {
      x: 5.6883692741394043,
      y: 1.5132107734680176,
      z: 1.5260922908782959,
    },
    cameraRotation: { x: 0.0000000437113883, y: -3.1415925025939941, z: 0.0 },
    artworkPosition: {
      x: 5.7558,
      y: 1.58,
      z: 2.5595452785491943,
    },
    artworkRotation: { x: 0.0, y: 3.1415, z: 0.0 },
  },
  {
    id: "dw-scene-10",
    name: "dw-scene-10",
    backgroundTexture: "textures/dw-scene-10_001.jpg",
    backgroundThumbnail: "textures/dw-scene-10_001_thumbnail.jpg",
    cameraFov: 65.4704525442152061,
    cameraPosition: {
      x: 0.2484215497970581,
      y: 1.1566178798675537,
      z: -3.8337998390197754,
    },
    cameraRotation: {
      x: -0.0000000754979013,
      y: 0.0000000000000002,
      z: -0.0000000000000002,
    },
    artworkPosition: {
      x: -0.0831409394741058,
      y: 1.4762130975723267,
      z: -5.4462566375732422,
    },
    artworkRotation: { x: 0.0, y: 0.0000001192092896, z: 0.0 },
  },
  {
    id: "dw-scene-10v2",
    name: "dw-scene-10v2",
    backgroundTexture: "textures/dw-scene-10v2_001.jpg",
    backgroundThumbnail: "textures/dw-scene-10v2_001_thumbnail.jpg",

    cameraFov: 45.4288247063344812,
    cameraPosition: {
      x: -0.0196599904447794,
      y: 1.010010838508606,
      z: -4.0306720733642578,
    },
    cameraRotation: {
      x: 0.0000000437113883,
      y: 0.0000000000000002,
      z: -0.0000000000000002,
    },
    artworkPosition: {
      x: -0.13,
      y: 1.2102259397506714,
      z: -5.4462566375732422,
    },
    artworkRotation: { x: 0.0, y: 0.0000001192092896, z: 0.0 },
  },
  {
    id: "dw-scene-12",
    name: "dw-scene-12",
    backgroundTexture: "textures/dw-scene-12_001.jpg",
    backgroundThumbnail: "textures/dw-scene-12_001_thumbnail.jpg",

    cameraFov: 42.7412445386863666,
    cameraPosition: {
      x: 0.4593649804592133,
      y: 0.9295742511749268,
      z: 1.5617642402648926,
    },
    cameraRotation: { x: 0.0000000437113918, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: 0.4532622098922729,
      y: 1.3019938468933105,
      z: -1.4099138975143433,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "dw-scene-12v2",
    name: "dw-scene-12v2",
    backgroundTexture: "textures/dw-scene-12v2_001.jpg",
    backgroundThumbnail: "textures/dw-scene-12v2_001_thumbnail.jpg",

    cameraFov: 42.7412445386863666,
    cameraPosition: {
      x: 0.9647351503372192,
      y: 0.9295742511749268,
      z: 2.6496372222900391,
    },
    cameraRotation: { x: 0.0000000437113918, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: 0.9586323499679565,
      y: 1.4383330345153809,
      z: -1.4099138975143433,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "dw-scene-13",
    name: "dw-scene-13",
    backgroundTexture: "textures/dw-scene-13_001.jpg",
    backgroundThumbnail: "textures/dw-scene-13_001_thumbnail.jpg",

    cameraFov: 42.7412445386863666,
    cameraPosition: {
      x: 0.4593649804592133,
      y: 0.9295742511749268,
      z: 1.5617642402648926,
    },
    cameraRotation: { x: 0.0000000437113918, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: 0.4532622098922729,
      y: 1.3019938468933105,
      z: -1.4099138975143433,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "dw-scene-14",
    name: "dw-scene-14",
    backgroundTexture: "textures/dw-scene-14_001.jpg",
    backgroundThumbnail: "textures/dw-scene-14_001_thumbnail.jpg",

    cameraFov: 30.9559756542767666,
    cameraPosition: {
      x: 0.831526517868042,
      y: 0.9636288285255432,
      z: 2.8492748737335205,
    },
    cameraRotation: { x: 0.0000000437113918, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: 0.8254237771034241,
      y: 1.4566757678985596,
      z: -1.4099138975143433,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "dw-scene-14v2",
    name: "dw-scene-14v2",
    backgroundTexture: "textures/dw-scene-14v2_001.jpg",
    backgroundThumbnail: "textures/dw-scene-14v2_001_thumbnail.jpg",

    cameraFov: 30.9559756542767666,
    cameraPosition: {
      x: 0.831526517868042,
      y: 0.9636288285255432,
      z: 2.8492748737335205,
    },
    cameraRotation: { x: 0.0000000437113918, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: 0.8254237771034241,
      y: 1.4566757678985596,
      z: -1.4099138975143433,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "dw-scene-11",
    name: "dw-scene-11",
    backgroundTexture: "textures/dw-scene-11_001.jpg",
    backgroundThumbnail: "textures/dw-scene-11_001_thumbnail.jpg",
    cameraFov: 47.9249779491563643,
    cameraPosition: {
      x: 3.5259442329406738,
      y: 1.1643478870391846,
      z: -0.3793791234493256,
    },
    cameraRotation: {
      x: 0.0000000437113847,
      y: 1.8313921689987183,
      z: 0.0000000000000003,
    },
    artworkPosition: {
      x: 0.5438520908355713,
      y: 1.5848233699798584,
      z: -0.0438308194279671,
    },
    artworkRotation: { x: 0.0, y: 1.570796012878418, z: 0.0 },
  },
  {
    id: "dw-scene-11v2",
    name: "dw-scene-11v2",
    backgroundTexture: "textures/dw-scene-11v2_001.jpg",
    backgroundThumbnail: "textures/dw-scene-11v2_001_thumbnail.jpg",

    cameraFov: 47.9249779491563643,
    cameraPosition: {
      x: 2.3563644886016846,
      y: 1.1694601774215698,
      z: -0.46404168009758,
    },
    cameraRotation: {
      x: 0.0000000437138716,
      y: 1.5695929527282715,
      z: -0.0000000000002413,
    },
    artworkPosition: {
      x: 0.5438520908355713,
      y: 1.4336187839508057,
      z: -0.4767844080924988,
    },
    artworkRotation: { x: 0.0, y: 1.570796012878418, z: 0.0 },
  },
  {
    id: "dw-scene-04",
    name: "dw-scene-04",
    backgroundTexture: "textures/dw-scene-09_001.jpg",
    backgroundThumbnail: "textures/dw-scene-09_001_thumbnail.jpg",
    cameraFov: 39.9662130437999608,
    cameraPosition: {
      x: 1.1034035682678223,
      y: 1.2234208583831787,
      z: 0.5814785957336426,
    },
    cameraRotation: { x: -0.0000000754979155, y: 1.5707962512969971, z: 0.0 },
    artworkPosition: {
      x: -1.9457986354827881,
      y: 1.2215412855148315,
      z: 0.5772244334220886,
    },
    artworkRotation: { x: -0.0, y: 1.5707962512969971, z: 0.0 },
  },
  {
    id: "dw-scene-03",
    name: "dw-scene-03",
    backgroundTexture: "textures/dw-scene-03_001.jpg",
    backgroundThumbnail: "textures/dw-scene-03_001_thumbnail.jpg",

    cameraFov: 49.5502811376638448,
    cameraPosition: {
      x: -0.726826548576355,
      y: 1.2819410562515259,
      z: 1.258256196975708,
    },
    cameraRotation: {
      x: -0.0000906325294636,
      y: 1.5734152793884277,
      z: -0.0000912726027309,
    },
    artworkPosition: {
      x: -4.419313907623291,
      y: 1.66,
      z: 1.2388708591461182,
    },
    artworkRotation: { x: 0.0, y: 1.5707962512969971, z: 0.0 },
  },
  {
    id: "dw-scene-05",
    name: "dw-scene-05",
    backgroundTexture: "textures/dw-scene-05_001.jpg",
    backgroundThumbnail: "textures/dw-scene-05_001_thumbnail.jpg",

    cameraFov: 46.7704421144275102,
    cameraPosition: {
      x: 4.0042390823364258,
      y: 1.0322459936141968,
      z: -2.5650391578674316,
    },
    cameraRotation: { x: -0.0000000754979013, y: 1.5707961320877075, z: 0.0 },
    artworkPosition: {
      x: 0.3174738585948944,
      y: 1.5783149003982544,
      z: -2.5429813861846924,
    },
    artworkRotation: { x: 0.0, y: 1.5707962512969971, z: 0.0 },
  },
  {
    id: "side-flower-1",
    name: "Side Flower 1",
    backgroundTexture: "textures/side-flower-1_002.jpg",
    backgroundThumbnail: "textures/side-flower-1_002_thumbnail.jpg",
    cameraPosition: {
      x: 0.0026348233222961,
      y: -0.1781124174594879,
      z: 3.1574902534484863,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    cameraFov: 31.6,
    artworkPosition: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    artworkRotation: { x: 0.0, y: 0.0, z: 0.0 },
  },
  {
    id: "art-gallery-2",
    name: "Art Gallery 2",
    backgroundTexture: "/textures/art-gallery-2_002.jpg",
    backgroundThumbnail: "/textures/art-gallery-2_002_thumbnail.jpg",
    artworkPosition: {
      x: -0.331,
      y: -0.1,
      z: 0,
    },
    artworkRotation: { x: 0.0, y: 0.0, z: 0.0 },
    cameraPosition: {
      x: -0.0842329263687134,
      y: 0.0529343485832214 - 0.2,
      z: 5.0348901748657227,
    },
    cameraRotation: {
      x: 0.0000000370773421,
      y: -0.011013220064342,
      z: -0.0008112287614495,
    },
    cameraFov: 39.6,
  },
  {
    id: "chair-wall-2_001",
    name: "Chair Wall 2 (001)",
    backgroundTexture: "/textures/chair-wall-2_001.jpg",
    backgroundThumbnail: "/textures/chair-wall-2_001_thumbnail.jpg",
    cameraPosition: {
      x: 0.2761110067367554,
      y: -0.4516356885433197,
      z: 4.1410121917724609,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    cameraFov: 39.6,
  },
  {
    id: "chair-wall-1_001",
    name: "Chair Wall 1 (001)",
    backgroundTexture: "/textures/chair-wall-1_001.jpg",
    backgroundThumbnail: "/textures/chair-wall-1_001_thumbnail.jpg",
    cameraPosition: {
      x: -0.0125991106033325,
      y: -0.4516356885433197,
      z: 3.5474584102630615,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    cameraFov: 39.6,
  },
  {
    id: "drawers-wall-1_001",
    name: "Drawers Wall 1 (001)",
    backgroundTexture: "/textures/drawers-wall-1_001.jpg",
    backgroundThumbnail: "/textures/drawers-wall-1_001_thumbnail.jpg",
    cameraPosition: {
      x: 0.3661301732063293,
      y: -0.3498598635196686,
      z: 2.7318882942199707,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    cameraFov: 39.6,
  },
  {
    id: "modern-hall-1_003",
    name: "Modern Hall 1 (003)",
    backgroundTexture: "/textures/modern-hall-1_003.jpg",
    backgroundThumbnail: "/textures/modern-hall-1_003_thumbnail.jpg",
    cameraPosition: {
      x: 0.1699629724025726,
      y: -0.6738281846046448,
      z: 4.4706306457519531,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    cameraFov: 39.6,
  },
  {
    id: "bedroom-1",
    backgroundTexture: "textures/bedroom-1_003.jpg",
    backgroundThumbnail: "textures/bedroom-1_002_thumbnail.jpg", //todo
    cameraFov: 54.104,

    cameraPosition: {
      x: -0.6625942587852478,
      y: 1.4918849468231201,
      z: 1.8133596181869507,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: -0.6490709185600281,
      y: 2.026878833770752,
      z: -1.8372454643249512,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "bedroom-2",
    backgroundTexture: "textures/bedroom-2_002.jpg",
    backgroundThumbnail: "textures/bedroom-2_002_thumbnail.jpg",
    cameraFov: 54.104,

    cameraPosition: {
      x: -2.5425941944122314,
      y: 1.4318850040435791,
      z: 2.1933596134185791,
    },
    cameraRotation: {
      x: 0.0000000437113883,
      y: -0.5235987901687622,
      z: 0.0000000000000006,
    },
    artworkPosition: {
      x: -0.6490709185600281,
      y: 2.026878833770752,
      z: -1.8372454643249512,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "dw-scene-02c",
    backgroundTexture: "textures/dw-scene-02c_002.jpg",
    backgroundThumbnail: "textures/dw-scene-02c_002_thumbnail.jpg",
    cameraFov: 53.1301,
    focalColor: "#9DACB5",

    cameraPosition: {
      x: 0.3044317066669464,
      y: 1.1883792877197266,
      z: -0.7026976346969604,
    },
    cameraRotation: {
      x: 0.0000006403839166,
      y: -3.1389732360839844,
      z: -0.0000002390435156,
    },
    artworkPosition: {
      x: 0.1796357184648514,
      y: 1.5121166706085205,
      z: 3.2280974388122559,
    },
    artworkRotation: { x: -0.0, y: 3.1415925025939941, z: -0.0 },
  },
  {
    id: "dw-scene-04_001",
    backgroundTexture: "textures/dw-scene-04_001.jpg",
    backgroundThumbnail: "textures/dw-scene-04_001.jpg",
    focalColor: "#DACCBC",

    cameraFov: 54.4321977163756614,
    cameraPosition: {
      x: -1.5351526737213135,
      y: 1.1991796493530273,
      z: -8.9680442810058594,
    },
    cameraRotation: {
      x: -0.0000000754979013,
      y: 0.0000000000000002,
      z: -0.0000000000000002,
    },
    artworkPosition: {
      x: -1.6590671539306641,
      y: 1.5649139881134033,
      z: -12.4622716903686523,
    },
    artworkRotation: { x: 0.0, y: 0.0000001192092896, z: 0.0 },
  },
  {
    id: "living-room-1",
    backgroundTexture: "textures/living-room-1_004.jpg",
    backgroundThumbnail: "textures/living-room-1_004_thumbnail.jpg",
    cameraFov: 54.104,

    cameraPosition: {
      x: -0.6625942587852478,
      y: 1.4918849468231201,
      z: 2.8867096900939941,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: -0.6490709185600281,
      y: 2.026878833770752,
      z: -1.8372454643249512,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "living-room-1b",
    backgroundTexture: "textures/living-room-1_006c.jpg",
    backgroundThumbnail: "textures/living-room-1_006c_thumbnail.jpg",
    focalColor: "#E2633B",
    cameraFov: 54.104,

    cameraPosition: {
      x: -0.6625942587852478,
      y: 1.2346663475036621,
      z: 2.0502963066101074,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: -0.6490709185600281,
      y: 2.026878833770752,
      z: -1.8372454643249512,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "living-room-1c",
    backgroundTexture: "textures/living-room-1_007.jpg",
    backgroundThumbnail: "textures/living-room-1_007_thumbnail.jpg",
    focalColor: "#97B055",
    cameraFov: 54.104,

    cameraPosition: {
      x: -0.6625942587852478,
      y: 1.2346663475036621,
      z: 2.0502963066101074,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: -0.6490709185600281,
      y: 2.026878833770752,
      z: -1.8372454643249512,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "living-room-1d",
    backgroundTexture: "textures/living-room-1_008.jpg",
    backgroundThumbnail: "textures/living-room-1_008_thumbnail.jpg",
    cameraFov: 54.104,
    focalColor: "#DCB13C",

    cameraPosition: {
      x: -0.6625942587852478,
      y: 1.2346663475036621,
      z: 2.0502963066101074,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: -0.6490709185600281,
      y: 2.026878833770752,
      z: -1.8372454643249512,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "living-room-1e",
    backgroundTexture: "textures/living-room-1_009.jpg",
    backgroundThumbnail: "textures/living-room-1_009_thumbnail.jpg",
    cameraFov: 54.104,
    focalColor: "#495EA1",

    cameraPosition: {
      x: -0.6625942587852478,
      y: 1.2346663475036621,
      z: 2.0502963066101074,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: -0.6490709185600281,
      y: 2.026878833770752,
      z: -1.8372454643249512,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "living-room-1f",
    backgroundTexture: "textures/living-room-1_010.jpg",
    backgroundThumbnail: "textures/living-room-1_010_thumbnail.jpg",
    cameraFov: 54.104,
    focalColor: "#2B2520",

    cameraPosition: {
      x: -0.6625942587852478,
      y: 1.2346663475036621,
      z: 2.0502963066101074,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: -0.6490709185600281,
      y: 2.026878833770752,
      z: -1.8372454643249512,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "living-room-1g",
    backgroundTexture: "textures/living-room-1_011.jpg",
    backgroundThumbnail: "textures/living-room-1_011_thumbnail.jpg",
    cameraFov: 54.104,
    focalColor: "#51662F",

    cameraPosition: {
      x: -0.6625942587852478,
      y: 1.2346663475036621,
      z: 2.0502963066101074,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: -0.6490709185600281,
      y: 2.026878833770752,
      z: -1.8372454643249512,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "living-room-1h",
    backgroundTexture: "textures/living-room-1_012.jpg",
    backgroundThumbnail: "textures/living-room-1_012_thumbnail.jpg",
    cameraFov: 54.104,
    focalColor: "#6A5CAA",

    cameraPosition: {
      x: -0.6625942587852478,
      y: 1.2346663475036621,
      z: 2.0502963066101074,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: -0.6490709185600281,
      y: 2.026878833770752,
      z: -1.8372454643249512,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "living-room-1i",
    backgroundTexture: "textures/living-room-1_013.jpg",
    backgroundThumbnail: "textures/living-room-1_013_thumbnail.jpg",
    cameraFov: 54.104,
    focalColor: "#C14F54",

    cameraPosition: {
      x: -0.6625942587852478,
      y: 1.2346663475036621,
      z: 2.0502963066101074,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: -0.6490709185600281,
      y: 2.026878833770752,
      z: -1.8372454643249512,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "living-room-1j",
    backgroundTexture: "textures/living-room-1_014.jpg",
    backgroundThumbnail: "textures/living-room-1_014_thumbnail.jpg",
    cameraFov: 54.104,
    focalColor: "#2D2D2D",

    cameraPosition: {
      x: -0.6625942587852478,
      y: 1.2346663475036621,
      z: 2.0502963066101074,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: -0.6490709185600281,
      y: 2.026878833770752,
      z: -1.8372454643249512,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "living-room-1k",
    backgroundTexture: "textures/living-room-1_015.jpg",
    backgroundThumbnail: "textures/living-room-1_015_thumbnail.jpg",
    cameraFov: 54.104,
    focalColor: "#C3C0C2",

    cameraPosition: {
      x: -0.6625942587852478,
      y: 1.2346663475036621,
      z: 2.0502963066101074,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: -0.6490709185600281,
      y: 2.026878833770752,
      z: -1.8372454643249512,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "living-room-1l",
    backgroundTexture: "textures/living-room-1_016.jpg",
    backgroundThumbnail: "textures/living-room-1_016_thumbnail.jpg",
    cameraFov: 54.104,
    focalColor: "#14B6C3",

    cameraPosition: {
      x: -0.6625942587852478,
      y: 1.2346663475036621,
      z: 2.0502963066101074,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: -0.6490709185600281,
      y: 2.026878833770752,
      z: -1.8372454643249512,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "blank-snow-70",
    backgroundTexture: "textures/blank-snow.jpg",
    backgroundThumbnail: "textures/blank-snow-70_thumbnail.jpg",
    cameraFov: 30,
    artworkOnlyMode: true,
    artworkOnlyCanvasFillRatio: 0.7,

    cameraPosition: {
      x: 0,
      y: 0,
      z: 3,
    },
    cameraRotation: {
      x: 0,
      y: 0,
      z: 0,
    },
    artworkPosition: {
      x: 0,
      y: 0,
      z: 0,
    },
    artworkRotation: { x: 0, y: 0, z: 0 },
  },
  {
    id: "blank-snow-angled-70",
    backgroundTexture: "textures/blank-snow.jpg",
    backgroundThumbnail: "textures/blank-snow-angled-70_thumbnail.jpg",
    cameraFov: 30,
    artworkOnlyMode: true,
    artworkOnlyCanvasFillRatio: 0.7,

    cameraPosition: {
      x: 0,
      y: 0,
      z: 3,
    },
    cameraRotation: {
      x: 0,
      y: 0,
      z: 0,
    },
    artworkPosition: {
      x: 0,
      y: 0,
      z: 0,
    },
    artworkRotation: { x: 0, y: -0.7, z: 0 },
  },
];

function MockupEditor({
  shop,
  app,
  product,
  setMockupEditorActiveProduct,
  imagePublishedCallback,
  manageSubscriptionCallback,
  inputField,
  setInputField,
  isInches,
  templateMode = false,
  allowLinkToVariant = false,
  betaMode,
  template,
  setMockupEditorActiveTemplate,
  saveTemplateCallback,
  deleteTemplateCallback,
  watermark,
}) {
  /* 
  We populate config in useEffect but note that renderSize is pre-populated. This is because it sort of sits outside 
  the scene and artwork parts of the config. Those parts describe the world. Whereas renderSize is an instruction to 
  Starboard to set the canvas to a particular size. This is used when Starboard is initialised and never read again
  (at least not currently...) so it must be stated here or Starboard will fall back to it's default render size (1400x1400).
   */
  const [config, setConfig] = useState<any>({});

  const [customScenes, setCustomScenes] = useState([]);

  const starboardRef = useRef();
  // TODO: find a better solution for the restored context issue. this approach is an absolute hack:
  const [showStarboard, setShowStarboard] = useState(true);
  const [captureMode, setCaptureMode] = useState(false);
  const [savedStarboardImage, setSavedStarboardImage] = useState("");

  const [loadingArtworkImage, setLoadingArtworkImage] = useState(false);

  const [automaticSceneSelection, setAutomaticSceneSelection] = useState(false);

  const [publishingMockup, setPublishingMockup] = useState(false);

  const [publishFailureToastActive, setPublishFailureToastActive] =
    useState(false);

  const [imageSource, setImageSource] = useState<any>();
  const [imageAspectRatio, setImageAspectRatio] = useState(1.0);
  const [croppedArtworkImage, setCroppedArtworkImage] = useState<any>();

  const updateCroppedArtworkImage = (imageDataUrl) => {
    calculateAndActOnArtworkImageAspectRatioAndSetCroppedArtworkImage(
      imageDataUrl
    );
  };

  enum Direction {
    Width,
    Height,
  }
  const inputsHandler = (value, fieldId) => {
    if ((fieldId === "width" || fieldId === "height") && isInches) {
      value = value * 2.51;
    }

    setInputField((prevInputField) => ({
      ...prevInputField,
      [fieldId]: value,
    }));

    if (
      (fieldId === "width" || fieldId === "height") &&
      imageSource &&
      inputField.lockAspectRatio
    ) {
      if (fieldId === "width") {
        // Update height (lock width)
        enforceAspectRatio(Direction.Width);
      } else if (fieldId === "height") {
        // Update width (lock height)
        enforceAspectRatio(Direction.Height);
      }
    }

    if (fieldId === "lockAspectRatio") {
      // If user has just ticked 'lock aspect ratio' then we force height based on current width
      enforceAspectRatio(Direction.Width);
    }
  };

  const enforceAspectRatio = (
    lockDirection: Direction,
    aspectRatio: number | null = null
  ) => {
    const ratio = aspectRatio ?? imageAspectRatio;
    if (lockDirection === Direction.Width) {
      setInputField((prevInputField) => ({
        ...prevInputField,
        height: (prevInputField.width / ratio).toFixed(1).toString(),
      }));
    } else if (lockDirection === Direction.Height) {
      setInputField((prevInputField) => ({
        ...prevInputField,
        width: (prevInputField.height * ratio).toFixed(1).toString(),
      }));
    }
  };

  const saveImage = async () => {
    const node = starboardRef.current as any;

    if (node !== undefined) {
      const blob = await grabImageBlob();
      // console.log(blob);
      const blobUrl = URL.createObjectURL(blob as Blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      if (product) {
        link.download = product.title + " (Created with Frame Up).jpg";
      } else {
        link.download = "Frame Up export.jpg";
      }
      link.click();

      // Create thin copy of the Starboard config, excluding artwork image data
      let thinConfig = {
        scene: {
          ...config.scene,
        },
        artwork: {
          ...config.artwork,
          imageSource: undefined,
        },
      };

      metric(app, "mockup_editor_image_saved", {
        productHandle: product.handle,
        starboardConfig: thinConfig,
      });

      // console.log("blobUrl:");
      // console.log(blobUrl);

      // const link = document.createElement("a");
      // link.href = blobUrl;
      // link.download = "mockup.jpg";

      // link.click();

      // window.open(blobUrl);

      // const base64Image = node.renderer.domElement.toDataURL("image/jpeg", 1.0);

      // const redirect = Redirect.create(app);

      // redirect.dispatch(Redirect.Action.REMOTE, {
      //   url: `https://44e0-185-71-38-141.ngrok.io/?imageDataUrl=${encodeURIComponent(
      //     base64Image
      //   )}`,
      //   newContext: true,
      // });

      // // Set Starboard to screenshot mode
      // setCaptureMode(true);

      // // Wait (1ms) so render thread can update the canvas
      // await new Promise((res) => setTimeout(res, 1));

      // const base64Image = node.renderer.domElement.toDataURL("image/jpeg", 1.0);

      // // Split into two parts
      // const parts = base64Image.split(';base64,');

      // // Hold the content type
      // const imageType = parts[0].split(':')[1];

      // // Decode Base64 string
      // const decodedData = window.atob(parts[1]);

      // // Create UNIT8ARRAY of size same as row data length
      // const uInt8Array = new Uint8Array(decodedData.length);

      // // Insert all character code into uInt8Array
      // for (let i = 0; i < decodedData.length; ++i) {
      //   uInt8Array[i] = decodedData.charCodeAt(i);
      // }

      // // Return BLOB image after conversion
      // const blob = new Blob([uInt8Array], { type: imageType });

      // link.href = node.renderer.domElement.toDataURL("image/jpeg", 1.0);

      // // Restore Starboard mode
      // setCaptureMode(false);

      // link.click();

      // const redirect = Redirect.create(app);

      // // window.location.href = node.renderer.domElement.toDataURL(
      // //   "image/jpeg",
      // //   1.0
      // // );

      // redirect.dispatch(Redirect.Action.REMOTE, {
      //   url: blobUrl,
      // });

      // // Restore Starboard mode
      // // setCaptureMode(false);
    }
  };

  const grabImageBlob = async () => {
    const node = starboardRef.current as any;

    if (node !== undefined) {
      setCaptureMode(true);
      const blob = await new Promise(async (resolve) => {
        // Set Starboard to screenshot mode
        // console.log("1");
        // setCaptureMode(true);

        // Wait (1ms) so render thread can update the canvas
        await new Promise((res) => setTimeout(res, 1));

        // console.log("2");
        node.renderer.domElement.toBlob(resolve, "image/jpeg", 0.9);

        // Restore Starboard mode
        // console.log("3");
        // setCaptureMode(false);
        // console.log("4");
      });
      setCaptureMode(false);
      return blob;
    }
  };

  useEffect(() => {
    // Fetch image and call handleImageFileChange
    if (product && product.images[0]) {
      setLoadingArtworkImage(true);
      fetch(product.images[0].originalSrc, {
        method: "GET",
      }).then((res) => {
        res.blob().then((image) => {
          handleImageFileChange(image);
        });
      });
    } else {
      handleImageFileChange(null);
    }
  }, [product]); //eslint-disable-line

  /* 
    Update Starboard config on form change
  */
  useEffect(() => {
    setConfig({
      scene:
        [...scenes, ...customScenes].find(
          (s) => s.id === inputField.sceneIndices?.at(-1)
        ) ?? scenes[0], // TODO: this silently falls back to first scene if matching scene can't be found
      scenes: inputField.sceneIndices?.map((i) =>
        [...scenes, ...customScenes].find((s) => s.id === i)
      ),
      artwork: {
        imagePath: templateMode
          ? "./images/artwork-placeholder.png"
          : undefined,
        imageSource: !templateMode ? croppedArtworkImage : undefined,
        size: { x: inputField.width / 100, y: inputField.height / 100 },
        contentMode: inputField.contentMode,
        kind: inputField.style,
        isBordered: inputField.isBordered,
        frameMaterial: inputField.frameMaterial, // TODO: shouldn't this only be included if style == framed?
        edgeFinish: inputField.edgeFinish, // TODO: shouldn't this only be included if style == canvas?
        isMounted: inputField.frameMounted,
      },
      linkToVariant: inputField.linkToVariant,
      uuid: template?.uuid,
      // renderSize: { x: 700, y: 700 },
    });

    // const jsonConfig = JSON.stringify(config);
    // console.log(jsonConfig);
    // const uriEncodedConfig = encodeURIComponent(jsonConfig);
    // console.log(`http://localhost:3006/#c=${uriEncodedConfig}`);

    // eslint-disable-next-line
  }, [inputField, croppedArtworkImage]);

  // If config has been set at launch (i.e. templateMode passing in a pre-existing template) then populate inputField.
  // TODO: could the fields not be directly backed by this? rather than this mapping process?
  useEffect(() => {
    if (template && template.artwork) {
      // Check it's been populated
      // console.log("template:");
      // console.log(template);
      setInputField({
        sceneIndices: template.scenes?.map((s) => s.id) ?? [],
        // sceneIndices: [
        //   scenes.findIndex((s) => s.id === template.scene.id) ?? 0,
        // ],
        // sceneIndex: scenes.findIndex((s) => s.id === template.scene.id) ?? 0, // TODO: load multiple
        width: template.artwork.size.x * 100,
        height: template.artwork.size.y * 100,
        contentMode: template.artwork.contentMode,
        style: template.artwork.kind,
        isBordered: template.artwork.isBordered,
        frameMaterial: template.artwork.frameMaterial,
        edgeFinish: template.artwork.edgeFinish,
        frameMounted: template.artwork.isMounted,
        linkToVariant: template.linkToVariant,
      });
    }
    // eslint-disable-next-line
  }, [scenes, customScenes]);

  // useEffect(() => {
  //   if (!shop) {
  //     setSelectedProduct({ title: "[No product selected]", images: [null] });
  //   }
  // }, [shop]);

  // Analyse colours and set appropriate scene automatically, if feature enabled
  useEffect(() => {
    if (
      automaticSceneSelection &&
      imageSource &&
      automaticallySetSceneBasedOnArtworkColors
    ) {
      automaticallySetSceneBasedOnArtworkColors(imageSource);
    }
    // eslint-disable-next-line
  }, [automaticSceneSelection]);

  /*
    Compress selected image for Starboard
  */
  const handleImageFileChange = async (fileObject) => {
    if (fileObject) {
      setLoadingArtworkImage(true);
    }

    if (fileObject === null) {
      setCroppedArtworkImage(null);
    }

    if (fileObject) {
      await new Promise((resolve, reject) => {
        new Compressor(fileObject, {
          // quality: 0.9,
          maxWidth: 2048,
          maxHeight: 2048,
          mimeType: "image/png",

          success: resolve,
          error: reject,
        });
      })
        .then(async (compressedFile) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(compressedFile as Blob);
          fileReader.onload = async () => {
            // Calculate image aspect ratio
            await calculateAndActOnArtworkImageAspectRatioAndSetCroppedArtworkImage(
              fileReader.result
            );

            // Store original loaded image
            setImageSource(fileReader.result);

            // Analyse colours and set appropriate scene automatically, if feature enabled
            if (automaticSceneSelection && fileReader.result) {
              await automaticallySetSceneBasedOnArtworkColors(
                fileReader.result
              );
            }

            setLoadingArtworkImage(false);
          };
        })
        .catch((err) => {
          console.log(err.message);

          setLoadingArtworkImage(false);
        });
    }
  };

  const automaticallySetSceneBasedOnArtworkColors = async (image) => {
    const palette = await getPalette(image as string);
    let artworkColor = new Color(palette.vibrant);
    var nearestScene;
    var nearestSceneColorDelta;
    scenes.forEach((scene) => {
      if (scene.focalColor) {
        let sceneColor = new Color(scene.focalColor);

        // @ts-ignore
        let colorDelta = artworkColor.deltaE76(sceneColor);

        if (!nearestScene || colorDelta < nearestSceneColorDelta) {
          nearestScene = scene;
          nearestSceneColorDelta = colorDelta;
        }
      }
    });
    // console.log(nearestScene);
    const nearestSceneIndex = scenes.indexOf(nearestScene);
    inputsHandler(nearestSceneIndex, "sceneIndex");
  };

  const calculateAndActOnArtworkImageAspectRatioAndSetCroppedArtworkImage =
    async (imageDataUrl) => {
      // let img = new Image();
      // img.onload = () => {
      //   setCroppedArtworkImage(imageDataUrl);

      //   const aspectRatio = img.width / img.height;
      //   setImageAspectRatio(aspectRatio);

      //   // If 'lock aspect ratio' enabled we force height based on image width
      //   if (inputField.lockAspectRatio) {
      //     enforceAspectRatio(Direction.Width, aspectRatio);
      //   }
      // };
      let img = (await loadImage(imageDataUrl)) as HTMLImageElement;

      setCroppedArtworkImage(imageDataUrl);

      const aspectRatio = img.width / img.height;
      setImageAspectRatio(aspectRatio);

      // If 'lock aspect ratio' enabled we force height based on image width
      if (inputField.lockAspectRatio) {
        enforceAspectRatio(Direction.Width, aspectRatio);
      }

      // img.src = imageDataUrl as string;
    };

  useEffect(() => {
    if (betaMode) {
      authenticatedFetch(app, "/api/scenes/", {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        response.json().then((data) => {
          // setScenes((s) => [...s, ...data.scenes]);
          setCustomScenes(data.scenes);
        });
      });
    }

    // eslint-disable-next-line
  }, []);

  const stageCanvasRef = useRef(null);

  // const aspectRatio = 1;
  // const aspectRatioWrapperRef = useAspectRatio(aspectRatio, stageCanvasRef);

  return (
    <>
      <CustomView condition={deviceType === "mobile"}>
        <Card
          title="Sorry, this feature is not supported on mobile devices"
          sectioned
        >
          <TextContainer>
            Please return to this app using a desktop computer
          </TextContainer>
        </Card>
      </CustomView>
      <CustomView condition={deviceType !== "mobile"}>
        <>
          {publishFailureToastActive && (
            <Toast
              error
              content="Mockup could not be published"
              onDismiss={() => setPublishFailureToastActive(false)}
            />
          )}
          <Modal
            open={savedStarboardImage.length > 0}
            onClose={() => setSavedStarboardImage("")}
            title=""
          >
            <Modal.Section>
              <TextContainer>
                <Text variant="headingMd" as="h2">
                  Right click to save or download the image
                </Text>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={savedStarboardImage}
                  alt=""
                />
              </TextContainer>
            </Modal.Section>
          </Modal>

          <div
            style={{
              display: "flex",
              flex: "1 1 0%",
              alignItems: "stretch",
              justifyContent: "stretch",
            }}
          >
            <div
              style={{
                flex: "0 0 27em",
                height: "40em",
                position: "relative",
              }}
            >
              <div
                style={{
                  overflowY: "scroll",
                  // overflowX: "hidden",
                  height: "36em",
                }}
              >
                <SettingsPanel
                  inputsHandler={inputsHandler}
                  inputField={inputField}
                  isInches={isInches}
                  handleImageFileChange={handleImageFileChange}
                  artworkImage={imageSource}
                  croppedArtworkImage={croppedArtworkImage}
                  setCroppedArtworkImage={updateCroppedArtworkImage}
                  loadingArtworkImage={loadingArtworkImage}
                  setLoadingArtworkImage={setLoadingArtworkImage}
                  imageAspectRatio={imageAspectRatio}
                  scenes={scenes}
                  customScenes={customScenes}
                  artworkStyle={inputField.style}
                  saveImage={saveImage}
                  grabImageBlob={grabImageBlob}
                  selectedProduct={product}
                  automaticSceneSelection={automaticSceneSelection}
                  setAutomaticSceneSelection={setAutomaticSceneSelection}
                  app={app}
                  templateMode={templateMode}
                  allowLinkToVariant={allowLinkToVariant}
                  betaMode={betaMode}
                  watermark={watermark}
                  manageSubscriptionCallback={manageSubscriptionCallback}
                />
              </div>
              <div
                style={{
                  height: "4em",
                  width: "100%",
                  // backgroundColor: "#f00",
                  borderWidth: 0,
                  borderStyle: "solid",
                  borderColor: "#CED1D3",
                  boxShadow: "0 -1.5px 0px 0 rgba(0, 0, 0, 0.1)",
                  paddingTop: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <Stack
                  spacing="tight"
                  alignment="center"
                  distribution="trailing"
                >
                  {templateMode && config.uuid && (
                    <Button
                      // icon={DeleteMinor}
                      variant="plain"
                      tone="critical"
                      // outline
                      onClick={() => deleteTemplateCallback(config, null)}
                    >
                      Delete
                    </Button>
                  )}
                  <Stack.Item fill></Stack.Item>
                  <Button
                    onClick={() => {
                      setMockupEditorActiveProduct &&
                        setMockupEditorActiveProduct(false);
                      setMockupEditorActiveTemplate &&
                        setMockupEditorActiveTemplate(false);
                    }}
                  >
                    Cancel
                  </Button>
                  {templateMode ? (
                    <Button
                      variant="primary"
                      onClick={() => saveTemplateCallback(config, null)}
                    >
                      {/* {config.uuid ? "Update" : "Add Template"} */}
                      Save
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      loading={publishingMockup}
                      disabled={!config.artwork?.imageSource} // TODO: should listen to Starboard "ready" messages rather than just checking if artwork image is set
                      onClick={async () => {
                        setPublishingMockup(true);

                        try {
                          const image = await grabImageBlob();
                          // Create thin copy of the Starboard config, excluding artwork image data
                          let thinConfig = {
                            scene: {
                              ...config.scene,
                            },
                            artwork: {
                              ...config.artwork,
                              imageSource: undefined,
                            },
                          };
                          const publishedImageURL = await publishImage(
                            app,
                            product,
                            image,
                            thinConfig
                          );
                          imagePublishedCallback(product, publishedImageURL);

                          setMockupEditorActiveProduct(false);
                        } catch (error) {
                          setPublishFailureToastActive(true);
                        }

                        setPublishingMockup(false);
                      }}
                      // connectedDisclosure={{
                      //   accessibilityLabel: "Other actions",
                      //   actions: [
                      //     {
                      //       content: "Save image",
                      //       onAction: async () => saveImage(),
                      //     },
                      //   ],
                      // }}
                    >
                      Publish
                    </Button>
                  )}
                </Stack>
              </div>
            </div>
            <div
              style={{
                // height: "40em",
                // width: "100%",
                // flex: "1 1 auto",
                backgroundColor:
                  templateMode || product ? "#4e4e4e" : "var(--p-color-bg)",
                borderRadius:
                  "0 var(--p-border-radius-150) var(--p-border-radius-150) 0",
                overflow: "clip",
                display: "flex",
                flex: "1 1 0%",
                flexGrow: "1",
                alignItems: "stretch",
                justifyContent: "stretch",
                // borderLeft: "0.0625rem solid var(--p-border-subdued)",
              }}
              ref={stageCanvasRef}
            >
              {!templateMode && !product ? (
                <div
                  style={{
                    width: "100%",
                    padding: "4em",
                    marginLeft: "5%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <Stack vertical spacing="extraLoose">
                    <p
                      style={{
                        fontSize: "1.7em",
                        fontWeight: 400,
                        color: "#3d3d3d",
                      }}
                    >
                      Select a <span style={{ fontWeight: 600 }}>wall art</span>{" "}
                      product to begin
                    </p>
                    <Stack>
                      {/* <Button
                        primary
                        size="large"
                        icon={SearchMinor}
                        onClick={() => setResourcePickerOpen(true)}
                      >
                        Select product
                      </Button> */}

                      {/* <Button
                          size="large"
                          onClick={() => setBulkRenderMode(true)}
                        >
                          Bulk mode
                        </Button> */}
                    </Stack>
                    <p
                      style={{
                        fontSize: "1em",
                        color: "#636363",
                        width: "30em",
                      }}
                    >
                      Tip: Frame Up assumes the product's first image is the
                      cropped artwork, without any framing or backdrop. You can
                      upload a different artwork image using the{" "}
                      <img
                        src="./icon_image_major.svg"
                        alt=""
                        style={{ margin: "0px 2px -3px 2px" }}
                      />{" "}
                      button.
                    </p>
                    {/* {shopIsInBulkBeta && (
                    <Button plain onClick={() => setBulkRenderMode(true)}>
                      Bulk rendering beta
                    </Button>
                  )} */}
                  </Stack>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    // margin: "auto",
                    // width: showStarboard ? "100%" : "90%",
                    // height: showStarboard ? "100%" : "90%",
                    // maxHeight: "100vh",
                    // backgroundColor: "#4E4E4E",
                    // alignItems: "center",
                    // justifyContent: "center",
                    // position: "relative",

                    // backgroundColor: "green",
                    display: "flex",
                    flex: "1 1 0%",
                    flexGrow: "1",
                    // width: "100%",
                    // height: "100%",
                    alignItems: "stretch",
                    justifyContent: "stretch",
                  }}
                  // ref={aspectRatioWrapperRef}
                >
                  <div
                    onContextMenu={() => {
                      metric(app, "mockup_editor_context_menu", {
                        productHandle: product.handle,
                      });
                    }}
                    style={{
                      width: "100%",
                      height: "100%",

                      display: "flex",
                      flex: "1 1 0%",
                      flexGrow: "1",
                      alignItems: "stretch",
                      justifyContent: "stretch",
                      // objectFit: "contain",
                      // backgroundColor: "red",
                      opacity: captureMode
                        ? 0.0
                        : loadingArtworkImage
                        ? 0.25
                        : 1.0,
                    }}
                  >
                    {showStarboard ? (
                      <Starboard
                        config={config!}
                        captureMode={captureMode}
                        ultraHighResolution={true}
                        onFinishLoading={() => {}}
                        contextWasLostAction={async () => {
                          console.log("Goodbye Starboard");
                          // console.log(ref);
                          // TODO: find a better solution for the restore context issue. this is a mess. waiting 1ms.
                          setShowStarboard(false);
                          // await new Promise((res) => setTimeout(res, 1));
                          // setShowStarboard(true);
                        }}
                        allowDrag={!templateMode}
                        watermarkLevel={
                          watermark
                            ? shop === "kfartstore.myshopify.com" ||
                              shop === "scmp-books.myshopify.com"
                              ? 2
                              : 1
                            : 0
                        }
                        dynamicCanvasSize={true}
                        ref={starboardRef as any}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flex: "1 1 0%",
                          flexGrow: "1",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          icon={RefreshIcon}
                          onClick={() => setShowStarboard(true)}
                        >
                          Reload canvas
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      </CustomView>
    </>
  );
}

function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener("load", () => resolve(img));
    img.addEventListener("error", reject); // don't forget this one
    img.src = url;
  });
}

export default MockupEditor;
