import { Spinner } from "@shopify/polaris";
import React from "react";

// import { CirclePlusMinor } from "@shopify/polaris-icons";
// import { Icon } from "@shopify/polaris";

function AspectImage({
  imageSource,
  dim,
  plusBadge,
  minusBadge,
  successBadge,
  renderInProgress,
}) {
  return (
    <>
      {renderInProgress ? (
        <div className="RenderingCell">
          <div
            // onClick={() => {
            //   setMockupEditorActiveProduct(product);
            // }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "rgba(0, 0, 0, 0.02)",
              padding: 0,
              borderRadius: 4,
              borderWidth: 1,
              borderStyle: "dashed",
              borderColor: "#b5b5b5",
              overflow: "hidden",
              width: "6em",
              height: "6em",
            }}
          >
            <div
              style={{
                width: "1.25em",
                height: "1.25em",
                opacity: 0.7,
              }}
            >
              <Spinner size="small" />
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "var(--p-color-bg-fill-tertiary)",
            padding: 0,
            borderRadius: 4,
            borderWidth: plusBadge ? 1 : 1,
            borderStyle: imageSource ? "solid" : "none",
            borderColor: minusBadge ? "#ddd" : "#b5b5b5",
            overflow: "hidden",
            width: "6em",
            height: "6em",
          }}
        >
          {imageSource && (
            <img
              src={imageSource}
              alt=""
              // loading="lazy"
              width="82em"
              height="82em"
              style={{
                objectFit: "scale-down",
                margin: "auto",
                maxWidth: "100%",
                maxHeight: "100%",
                borderWidth: 1.2,
                // borderStyle: "solid",
                // borderStyle: "dashed",
                // borderColor: "rgba(0, 0, 0, 0.0)",
                // boxShadow: "0px 0px 0px 1px black inset",

                opacity: minusBadge ? 0.3 : dim ? 0.6 : 1.0,
              }}
            />
          )}
          {plusBadge && (
            <img
              style={{
                position: "absolute",
                width: "1.6em",
                margin: "4.1em 0.1em 0 0",
              }}
              src="./icons/change_indicator_add_outline.svg"
              alt=""
            />
          )}
          {minusBadge && (
            <img
              style={{
                position: "absolute",
                width: "1.6em",
                margin: "4.1em 0.1em 0 0",
              }}
              src="./icons/change_indicator_remove_outline.svg"
              alt=""
            />
          )}
          {successBadge && (
            <img
              style={{
                position: "absolute",
                width: "1.5em",
                margin: "4.1em 0.1em 0 0",
              }}
              src="./icons/change_indicator_success.svg"
              alt=""
            />
          )}
        </div>
      )}
    </>
  );
}

export default AspectImage;
