import React from "react";

function SectionSeparator() {
  return <div className="border-t-[1.5px] border-gray-200 my-6"></div>;
}

function RowSeparator() {
  return <div className="border-t border-gray-200"></div>;
}

export { SectionSeparator, RowSeparator };
