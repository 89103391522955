import React, { useEffect, useState, lazy, Suspense } from "react";

import "./style.css";

import "@shopify/polaris/build/esm/styles.css";
import {
  Page,
  LegacyCard as Card,
  TextField,
  Icon,
  Pagination,
  LegacyStack as Stack,
  Spinner,
  Button,
  Modal,
  Toast,
  Frame,
  Popover,
  ActionList,
  Text,
  Banner,
  Badge,
  Link,
  SkeletonBodyText,
  SkeletonDisplayText,
} from "@shopify/polaris";
import {
  SearchIcon,
  MenuHorizontalIcon,
  ProductIcon,
  StoreIcon,
  ViewIcon,
  CheckIcon,
  SettingsIcon,
  ContractIcon,
  RefreshIcon,
  WandIcon,
} from "@shopify/polaris-icons";
// import { Redirect } from "@shopify/app-bridge/actions";

//@ts-ignore
import { metric, authenticatedFetch } from "./APIUtils.ts";

import { CustomView, deviceType } from "react-device-detect";

// import KeepAlive, { AliveScope } from "react-activation";
// TODO: remove react-activation dependency

import Pusher from "pusher-js";

//@ts-ignore
import AspectImage from "./AspectImage.tsx";

//@ts-ignore
import EditRulesPage from "./EditRulesPage.tsx";

//@ts-ignore
import SkippedProductsList from "./SkippedProductsList.tsx";

//@ts-ignore
import ManageSubscriptionPage from "./ManageSubscriptionPage.tsx";

//@ts-ignore
import LearnAboutAutomationModal from "./LearnAboutAutomationModal.tsx";

//@ts-ignore
import HoverImage from "./HoverImage.tsx";

// import { gtagSetup } from "./gtagHelper.js";

import ReactHover, { Trigger, Hover } from "react-hover";
import { ContentMode } from "starboard";
// import ReactDOM from "react-dom";

//@ts-ignore
const MockupEditor = lazy(() => import("./MockupEditor.tsx"));

function ProductCell({
  product,
  app,
  // redirect,
  onlyShowProductsWithUnpublishedChanges,
  setMockupEditorActiveProduct,
}) {
  const [moreActionsOpen, setMoreActionsOpen] = useState(false);

  const activator = (
    <Button
      variant="plain"
      size="slim"
      icon={MenuHorizontalIcon}
      onClick={() => setMoreActionsOpen(!moreActionsOpen)}
    ></Button>
  );

  return (
    <Card.Section>
      <Stack>
        <Stack.Item fill>
          <div style={{ height: "24px" }}>
            {product ? product.title : <SkeletonDisplayText size="small" />}
          </div>
        </Stack.Item>
        <Stack.Item>
          {product && (
            <Popover
              active={moreActionsOpen}
              activator={activator}
              autofocusTarget="none"
              onClose={() => setMoreActionsOpen(false)}
            >
              <ActionList
                actionRole="menuitem"
                sections={[
                  {
                    // title: "Product options",
                    items: [
                      {
                        content: "View in admin",
                        icon: ProductIcon,
                        onAction: () => {
                          // console.log(redirect);
                          const productIdClean = product.id.split("/").pop();
                          window.open(
                            `shopify://admin/products/${productIdClean}`
                          );
                          metric(app, "open_product_in_admin", {
                            productHandle: product.handle,
                            productId: productIdClean,
                          });
                        },
                      },
                      {
                        content: product.onlineStoreUrl
                          ? "View on online store"
                          : "Preview on online store",
                        icon: StoreIcon,
                        onAction: () => {
                          window.open(
                            product.onlineStoreUrl ??
                              product.onlineStorePreviewUrl,
                            "_blank"
                          );
                          const productIdClean = product.id.split("/").pop();
                          metric(app, "open_product_in_storefront", {
                            productHandle: product.handle,
                            productId: productIdClean,
                          });
                        },
                      },
                    ],
                  },
                ]}
              />
            </Popover>
          )}
        </Stack.Item>
      </Stack>

      <div style={{ marginTop: "1em" }}>
        <Stack wrap={false}>
          <Stack.Item fill>
            <Stack spacing="tight">
              {product
                ? product.images.map((image, i) => {
                    return (
                      <ReactHover options={{ followCursor: false }} key={i}>
                        <Trigger type="trigger">
                          <AspectImage
                            imageSource={image.transformedSrc}
                            dim={
                              onlyShowProductsWithUnpublishedChanges &&
                              image.source === "SHOPIFY"
                            }
                            plusBadge={
                              image.source === "RENDERBOT" &&
                              image.state === "RENDERED"
                            }
                            minusBadge={
                              image.source === "SHOPIFY" &&
                              image.generatedByRenderbot &&
                              image.dueForUnpublish
                            }
                            successBadge={
                              image.state === "PUBLISHED" ||
                              (image.source === "SHOPIFY" &&
                                image.generatedByRenderbot &&
                                !image.dueForUnpublish)
                            }
                            renderInProgress={
                              image.source === "RENDERBOT" &&
                              image.state === "IN_PROGRESS"
                            }
                          />
                        </Trigger>
                        <Hover type="hover">
                          <HoverImage imageSrc={image.originalSrc} />
                        </Hover>
                      </ReactHover>
                    );
                  })
                : [0, 1].map((item, i) => (
                    <div key={i}>
                      <AspectImage
                        imageSource={undefined}
                        dim={undefined}
                        plusBadge={undefined}
                        minusBadge={undefined}
                        successBadge={undefined}
                        renderInProgress={undefined}
                      />
                    </div>
                  ))}
              {!onlyShowProductsWithUnpublishedChanges && product && (
                <div className="PlusCell">
                  <div
                    onClick={() => {
                      setMockupEditorActiveProduct(product);
                      metric(app, "mockup_editor_opened", {
                        productHandle: product.handle,
                      });
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // backgroundColor: "rgba(0, 0, 0, 0.02)",
                      padding: 0,
                      borderRadius: 4,
                      borderWidth: 1,
                      borderStyle: "dashed",
                      borderColor: "#b5b5b5",
                      overflow: "hidden",
                      width: "6em",
                      height: "6em",
                    }}
                  >
                    <img
                      src="./icons/shopify_plus_blue.svg"
                      width="20"
                      height="20"
                      style={{ width: "1.4em" }}
                      alt=""
                    />
                  </div>
                </div>
              )}
            </Stack>
          </Stack.Item>
          {/* {onlyShowProductsWithUnpublishedChanges && (
            <Stack.Item>
              <Stack vertical alignment="trailing">
                <Button size="slim" icon={TickSmallMinor}>
                  Publish change
                </Button>
              </Stack>
            </Stack.Item>
          )} */}
        </Stack>
      </div>
    </Card.Section>
  );
}

function ProductImageReviewer({ shop, app }) {
  useEffect(() => {
    metric(app, "app_launched");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line
  const [betaMode, setBetaMode] = useState(false);

  const [products, setProducts] = useState<any>(null);
  const [pageInfo, setPageInfo] = useState<any>(null);
  const [jobInfo, setJobInfo] = useState<any>(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState<any>(null);

  const [
    onlyShowProductsWithUnpublishedChanges,
    setOnlyShowProductsWithUnpublishedChanges,
  ] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const isInches =
    window.navigator.language === "en-US" || window.navigator.language === "my";
  const [inputField, setInputField] = useState<any>({
    width: isInches ? 15 * 2.51 : "40",
    height: isInches ? 15 * 2.51 : "40",
    lockAspectRatio: true,
    contentMode: ContentMode,
    style: "canvas",
    isBordered: false,
    frameMaterial: "natural",
    edgeFinish: "white",
    frameMounted: false,
    sceneIndex: "blank-white-70",
    sceneIndices: ["blank-white-70"],
  });

  // useEffect(() => {
  //   // Initial fetch
  //   fetchProducts("", null, null);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // Mount
  // const [pusherChannel, setPusherChannel] = useState<any>(null);
  useEffect(() => {
    // console.log("useEffect setting up Pusher");
    const pusher = new Pusher("b131f649dc1b637a1da8", {
      cluster: "mt1",
      authorizer: function (channel, options) {
        return {
          authorize: async function (socketId, callback) {
            // Do some ajax to get the auth string

            // console.log(socketId);

            const response = await authenticatedFetch(
              app,
              "/api/pusher/auth/",
              {
                method: "POST",
                body: JSON.stringify({
                  socket_id: socketId,
                }),
                headers: {
                  "content-type": "application/json",
                  accept: "application/json",
                },
              }
            );
            let data = await response.json();

            callback(null, { auth: data.auth });
          },
        };
      },
    });

    const channel = pusher.subscribe("private-" + shop);
    channel.bind("job-update", async (data) => {
      console.log("Received Pusher event:");
      console.log(data);

      // TODO: only set this if data.job is defined? why is it ever not defined?
      setJobInfo((_jobInfo) => ({ ..._jobInfo, ...data.job }));

      if (data.job && data.job.state === "COMPLETE") {
        // Force a refresh so we get the products-to-be-unpublished
        // TODO: this assumes onlyShowProductsWithUnpublishedChanges is ticked... if not then this is completely pointless. needs design thinking anyway
        // TODO: fetch the same page the user is looking at rather than jumping back to first page
        setProducts(null);
        setPageInfo(null);
        await fetchProducts(searchTerm, true, null, null);
        await fetchStatus();
      }

      if (data.product) {
        setProducts((prev) => {
          const pageSize = 25;
          var _products = prev ? [...prev] : [];

          var i = _products.findIndex(
            (_element) => _element.id === data.product.id
          );
          if (i <= -1) {
            if (_products.length < pageSize) {
              _products.push(data.product);
              i = _products.length - 1;
            } else {
              setPageInfo((p) => {
                return {
                  hasPreviousPage: p?.hasPreviousPage,
                  hasNextPage: true,
                  startCursor: p?.startCursor,
                  endCursor: _products[pageSize - 1].id,
                };
              });
            }
          }

          // If i < 0 then we're ignoring the inbound product from Pusher because it's beyond the current page
          if (i >= 0 && data.render) {
            // Add render data (don't add if matching image with renderedImageUrl already exists)
            const matchingImageIndex = _products[i].images.findIndex(
              (e) => e.id === data.render.id
            );
            if (matchingImageIndex === -1) {
              _products[i].images.push(data.render);
            } else {
              _products[i].images[matchingImageIndex] = data.render;
            }
          }
          return _products;
        });
      }
    });
    channel.bind("publish-update", (data) => {
      console.log("Received Pusher event:");
      console.log(data);

      // setJobInfo((_jobInfo) => ({ ..._jobInfo, ...data.job }));

      if (data.change) {
        setProducts((prev) => {
          var _products = prev ? [...prev] : [];

          var i = _products.findIndex(
            (_element) => _element.id === data.change.productId
          );

          if (i >= 0) {
            const matchingImageIndex = _products[i].images.findIndex(
              (e) => e.id === data.change.id
            );
            if (matchingImageIndex >= 0) {
              if (data.change.state === "PUBLISHED") {
                _products[i].images[matchingImageIndex].state =
                  data.change.state; // Update image state
              } else if (data.change.state === "UNPUBLISHED") {
                _products[i].images.splice(matchingImageIndex, 1); // Remove image
              }
            } else {
              console.log("Could not find matching image");
            }
          } else {
            console.log("Could not find matching product");
          }
          return _products;
        });
      }

      if (data.job) {
        setJobInfo((_jobInfo) => ({ ..._jobInfo, ...data.job }));

        if (data.job.state === "PUBLISHED") {
          setIsPublishingActiveJob(false);
        } else if (data.job.state === "PUBLISHING") {
          setIsPublishingActiveJob(true);
        }
      }
    });

    // setPusherChannel(channel);

    // Unmount
    return () => {
      // Disconnect Pusher (helpful when hot reloading)
      channel.unbind_all();
      pusher.unsubscribe("private-" + shop);
      pusher.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [abortController, setAbortController] = useState(new AbortController());
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState<any>(null);

  const [mockupEditorActiveProduct, setMockupEditorActiveProduct] =
    useState<any>(null);
  const [publishSuccessToastActive, setPublishSuccessToastActive] =
    useState(false);

  const [isPublishingActiveJob, setIsPublishingActiveJob] = useState(false);

  const [editRulesPageActive, setEditRulesPageActive] = useState(false);
  const [skippedProductsListActive, setSkippedProductsListActive] =
    useState(false);
  const [manageSubscriptionPageActive, setManageSubscriptionPageActive] =
    useState(false);
  const [learnAboutAutomationModalActive, setLearnAboutAutomationModalActive] =
    useState(false);

  const [viewModePopoverActive, setViewModePopoverActive] = useState(false);
  const [settingsPopoverActive, setSettingsPopoverActive] = useState(false);

  const fetchProducts = async (term, onlyUnpublished, fromCursor, toCursor) => {
    if (isLoading) {
      // console.log("Overruling with: " + term);
      abortController.abort();
    }
    const ac = new AbortController();
    setAbortController(ac);

    setIsLoading(true);
    setFetchError(null);

    try {
      let path;
      if (onlyUnpublished) {
        path =
          `/api/products/unpublished?` +
          (fromCursor ? `&from_cursor=${fromCursor}` : "") +
          (toCursor ? `&to_cursor=${toCursor}` : "");
      } else {
        path =
          `/api/products/all?search_term=${term}` +
          (fromCursor ? `&from_cursor=${fromCursor}` : "") +
          (toCursor ? `&to_cursor=${toCursor}` : "");
      }
      const responseRaw = await authenticatedFetch(
        app,
        path,
        {
          headers: {
            "Content-Type": "application/json",
          },
          signal: ac.signal,
        },
        true
      );
      // console.log("Processing: " + term);
      const response = await responseRaw.json();
      // console.log("Processed: " + term);
      // console.log(response);

      setProducts(() => {
        return response.products;
      });
      setPageInfo(response.pageInfo);

      if (onlyUnpublished) {
        setJobInfo((_jobInfo) => ({
          ..._jobInfo,
          ...response.job,
        }));
      }

      if (response.subscription) {
        setSubscriptionInfo(response.subscription);
      }

      setIsLoading(false);
    } catch (err) {
      if (err.name === "AbortError") {
        // console.log("Aborted: " + term);
      } else {
        setFetchError(err);
        setIsLoading(false);
      }
    }
  };

  // Refresh data when rules modal is saved
  async function didSaveRules() {
    console.log("didSaveRules() hit");
    setProducts(null);
    setPageInfo(null);
    setJobInfo(null);
    setOnlyShowProductsWithUnpublishedChanges(true);

    await fetchProducts(searchTerm, true, null, null);

    // await fetchProducts(
    //   searchTerm,
    //   onlyShowProductsWithUnpublishedChanges,
    //   null,
    //   null
    // );

    // // Cancel any existing rendering job
    // pusherChannel.trigger('client-render-stop', null)
  }

  // Refresh data when search term field or review checkbox change
  // useEffect(() => {
  //   const f = async () => {
  //     setProducts(null);
  //     setPageInfo(null);
  //     await fetchProducts(
  //       searchTerm,
  //       onlyShowProductsWithUnpublishedChanges,
  //       null,
  //       null
  //     );
  //   };
  //   f();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [onlyShowProductsWithUnpublishedChanges, searchTerm]);

  async function fetchStatus() {
    const response = await authenticatedFetch(app, "/api/mockups/status", {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    });
    let data = await response.json();

    setJobInfo((_jobInfo) => ({ ..._jobInfo, ...data.job }));

    if (data.subscription) {
      setSubscriptionInfo(data.subscription);
    }

    // If last job hit product limit, and that limit has now been increased, go directly to "review changes" tab
    if (
      data.job?.productLimitReached &&
      data.subscription?.productLimit > data.job?.productLimit
    ) {
      setOnlyShowProductsWithUnpublishedChanges(true);
      setProducts(null);
      setPageInfo(null);
      await fetchProducts(searchTerm, true, null, null);
    }

    setBetaMode(data.betaMode);

    // gtagSetup(); // TODO: remove now?
  }

  useEffect(() => {
    fetchStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editRulesPageActive) {
      metric(app, "automation_settings_opened");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRulesPageActive]);

  useEffect(() => {
    if (manageSubscriptionPageActive) {
      metric(app, "manage_subscription_opened");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageSubscriptionPageActive]);

  // // Debugging re-rendering of products list
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setProducts((p) => {
  //       if (p) {
  //         let x = [...p];
  //         x[0].images = [
  //           ...x[0].images.slice(0, Math.max(1, x[0].images.length - 1)),
  //         ];
  //         console.log(x[0].images.length);
  //         return x;
  //       }
  //       return p;
  //     });
  //   }, 3000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  // const tabs = betaMode
  //   ? [
  //       {
  //         id: "image-manager",
  //         content: "Image Manager",
  //       },
  //       {
  //         id: "bulk-mockups",
  //         content: "Bulk Mockups",
  //         // content: (
  //         //   <span>
  //         //     Bulk Mockups{" "}
  //         //     <div
  //         //       style={{
  //         //         display: "inline",
  //         //         marginLeft: "0.3em",
  //         //         backgroundColor: "#8171C6", //"#634CDB",
  //         //         padding: "2px 5px 2px 5px",
  //         //         color: "#fff",
  //         //         fontWeight: "500",
  //         //         fontSize: "9pt",
  //         //         borderRadius: "4px",
  //         //       }}
  //         //     >
  //         //       Beta
  //         //     </div>
  //         //   </span>
  //         // ),
  //       },
  //     ]
  //   : [
  //       {
  //         id: "image-manager",
  //         content: "Image Manager",
  //       },
  //     ];

  // const redirect = app && Redirect.create(app);

  return (
    // @ts-ignore
    // <AliveScope>
    <Frame>
      <Page>
        {/* This hack artificially extends the tab bar underline, see style.css also */}
        <div style={{ height: "50px", padding: "1.3em 2em 0em 2em" }}>
          <Stack alignment="center" spacing="loose">
            <CustomView condition={deviceType !== "mobile"}>
              <Stack.Item>
                {!jobInfo && (
                  <div style={{ width: "18em" }}>
                    <SkeletonBodyText lines={1} />
                  </div>
                )}
                {jobInfo && Object.keys(jobInfo).length === 0 && (
                  <Stack spacing="tight">
                    <div
                      style={{
                        color: "#4D5053",
                        display: "flex",
                        justifyContent: "center",
                        gap: "7px",
                      }}
                    >
                      <Button
                        variant="primary"
                        disabled={
                          editRulesPageActive || manageSubscriptionPageActive
                        }
                        onClick={() => {
                          // setOnlyShowProductsWithUnpublishedChanges(true);
                          setLearnAboutAutomationModalActive(true);
                          // setEditRulesPageActive(false);
                          // setManageSubscriptionPageActive(false);

                          metric(app, "learn_about_automation_opened");
                        }}
                        icon={WandIcon}
                      >
                        Create mockups automatically
                      </Button>
                    </div>
                  </Stack>
                )}
                {(jobInfo?.state === "IN_PROGRESS" ||
                  jobInfo?.state === "PROCESSING_RULES") && (
                  <Stack spacing="tight">
                    <div
                      style={{
                        color: "#4D5053",
                        display: "flex",
                        justifyContent: "center",
                        gap: "7px",
                      }}
                    >
                      <img
                        src="./icons/job_indicator_in_progress.svg"
                        width="16"
                        height="20"
                        alt=""
                      />
                      <Link
                        monochrome
                        removeUnderline
                        onClick={async () => {
                          setOnlyShowProductsWithUnpublishedChanges(true);
                          setEditRulesPageActive(false);
                          setManageSubscriptionPageActive(false);

                          setProducts(null);
                          setPageInfo(null);
                          await fetchProducts(searchTerm, true, null, null);
                        }}
                        url="#"
                      >
                        Running automation
                        {jobInfo.productsProcessedCount >= 1 &&
                          " (" +
                            (
                              (jobInfo.productsProcessedCount /
                                jobInfo.productsToProcessCount) *
                              100
                            ).toFixed() +
                            "% complete)"}
                      </Link>
                    </div>
                  </Stack>
                )}
                {jobInfo?.state === "COMPLETE" && (
                  <Stack spacing="tight">
                    <div
                      style={{
                        color: "#4D5053",
                        display: "flex",
                        justifyContent: "center",
                        gap: "7px",
                      }}
                    >
                      <img
                        src="./icons/job_indicator_pending_review.svg"
                        width="16"
                        height="20"
                        alt=""
                      />
                      <Link
                        monochrome
                        onClick={async () => {
                          setOnlyShowProductsWithUnpublishedChanges(true);
                          setEditRulesPageActive(false);
                          setManageSubscriptionPageActive(false);

                          setProducts(null);
                          setPageInfo(null);
                          await fetchProducts(searchTerm, true, null, null);
                        }}
                        url="#"
                      >
                        {jobInfo?.rendersToPublishCount +
                          jobInfo?.rendersToUnpublishCount}{" "}
                        change
                        {jobInfo?.rendersToPublishCount +
                          jobInfo?.rendersToUnpublishCount !==
                          1 && "s"}{" "}
                        for review
                        {/* <Link
                      monochrome
                      onClick={() => {
                        setOnlyShowProductsWithUnpublishedChanges(true);
                        setEditRulesPageActive(false);
                        setManageSubscriptionPageActive(false);
                      }}
                      url="#"
                    >
                      review
                    </Link> */}
                      </Link>
                    </div>
                  </Stack>
                )}
                {jobInfo?.state === "PUBLISHING" && (
                  <Stack spacing="tight">
                    <div
                      style={{
                        color: "#4D5053",
                        display: "flex",
                        justifyContent: "center",
                        gap: "7px",
                      }}
                    >
                      <img
                        src="./icons/job_indicator_in_progress.svg"
                        width="16"
                        height="20"
                        alt=""
                      />
                      <Link
                        monochrome
                        removeUnderline
                        onClick={async () => {
                          setOnlyShowProductsWithUnpublishedChanges(true);
                          setEditRulesPageActive(false);
                          setManageSubscriptionPageActive(false);

                          setProducts(null);
                          setPageInfo(null);
                          await fetchProducts(searchTerm, true, null, null);
                        }}
                        url="#"
                      >
                        Publishing changes
                        {jobInfo.productsPublishedCount >= 1 &&
                          " (" +
                            (
                              (jobInfo.productsPublishedCount /
                                jobInfo.productsToPublishCount) *
                              100
                            ).toFixed() +
                            "% complete)"}
                      </Link>
                    </div>
                  </Stack>
                )}
                {jobInfo?.state === "PUBLISHED" && (
                  <Stack spacing="tight">
                    <div
                      style={{
                        color: "#4D5053",
                        display: "flex",
                        justifyContent: "center",
                        gap: "7px",
                      }}
                    >
                      <img
                        src="./icons/job_indicator_published.svg"
                        width="20"
                        height="20"
                        alt=""
                      />
                      <Link
                        monochrome
                        removeUnderline
                        onClick={async () => {
                          setOnlyShowProductsWithUnpublishedChanges(true);
                          setEditRulesPageActive(false);
                          setManageSubscriptionPageActive(false);

                          setProducts(null);
                          setPageInfo(null);
                          await fetchProducts(searchTerm, true, null, null);
                        }}
                        url="#"
                      >
                        {jobInfo?.productsPublishedCount} products with
                        automated mockups
                      </Link>
                    </div>
                  </Stack>
                )}
              </Stack.Item>
            </CustomView>
            <Stack.Item fill></Stack.Item>

            <div
              style={{
                color: "#7C7C7C",
                display: "block",
                margin: "0.56em 0", // TODO: not needed after beta ends
              }}
            >
              {/* <Button
                plain
                // monochrome
                removeUnderline
                url="mailto:support@frameup.app"
                // icon={QuestionMarkMinor}
              >
                Contact us
              </Button> */}
              <Link removeUnderline url="mailto:support@frameup.app">
                Contact Support
              </Link>
            </div>

            {
              <Popover
                active={settingsPopoverActive}
                activator={
                  <Button
                    disclosure={"down"}
                    onClick={() => setSettingsPopoverActive((s) => !s)}
                  >
                    Settings
                  </Button>
                }
                autofocusTarget="none"
                preferredAlignment="right"
                onClose={() => {
                  setSettingsPopoverActive(false);
                }}
              >
                <ActionList
                  actionRole="menuitem"
                  sections={[
                    {
                      title: "Settings",
                      items: [
                        {
                          content: "Automation Settings",
                          icon: SettingsIcon,
                          onAction: () => {
                            setEditRulesPageActive(true);
                            setSettingsPopoverActive(false);

                            setManageSubscriptionPageActive(false);
                          },
                        },
                        {
                          content: "Manage Subscription",
                          icon: ContractIcon,
                          onAction: () => {
                            setManageSubscriptionPageActive(true);
                            setSettingsPopoverActive(false);

                            setEditRulesPageActive(false);
                          },
                        },
                      ],
                    },
                    jobInfo && Object.keys(jobInfo).length > 0
                      ? {
                          title: "Actions",
                          items: [
                            {
                              content: "Re-run Automation",
                              icon: RefreshIcon,
                              onAction: () => {
                                // TODO: start job...
                                setSettingsPopoverActive(false);
                                setManageSubscriptionPageActive(false);

                                const f = async () => {
                                  // Authenticated fetch POST operation
                                  const response = await authenticatedFetch(
                                    app,
                                    "/api/mockups/startJob",
                                    {
                                      method: "post",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  );
                                  const responseJSON = await response.json();
                                  console.log(responseJSON);

                                  didSaveRules(); // TODO: rename function...
                                };
                                f();
                              },
                            },
                          ],
                        }
                      : { items: [] },
                  ]}
                />
              </Popover>
            }
          </Stack>
        </div>

        {/* This hack places content on the right side of the tab bar */}
        {/* <div
          style={{
            display: "block",
            marginTop: "-2.7em",
            marginBottom: "1.1em",
            paddingRight: "2.6em",
            textAlign: "right",
          }}
        >
          <Button plain url="mailto:support@frameup.app">
            support@frameup.app
          </Button>
        </div> */}
        {!(editRulesPageActive || manageSubscriptionPageActive) ? (
          <Page>
            {publishSuccessToastActive && (
              <Toast
                content="Mockup published"
                onDismiss={() => setPublishSuccessToastActive(false)}
              />
            )}

            <br />

            <Card>
              {/* {!onlyShowProductsWithUnpublishedChanges && ( */}
              <Card.Section>
                <Stack vertical>
                  <Stack alignment="center">
                    {
                      <Stack.Item>
                        <Popover
                          active={viewModePopoverActive}
                          activator={
                            <Button
                              icon={ViewIcon}
                              disclosure={"down"}
                              onClick={() =>
                                setViewModePopoverActive((s) => !s)
                              }
                            >
                              {onlyShowProductsWithUnpublishedChanges
                                ? "Review changes"
                                : "All products"}
                            </Button>
                          }
                          autofocusTarget="none"
                          onClose={() => {
                            setViewModePopoverActive(false);
                          }}
                        >
                          <ActionList
                            actionRole="menuitem"
                            items={[
                              {
                                content: "All products",
                                active: !onlyShowProductsWithUnpublishedChanges,
                                onAction: async () => {
                                  setOnlyShowProductsWithUnpublishedChanges(
                                    false
                                  );
                                  setViewModePopoverActive(false);

                                  setProducts(null);
                                  setPageInfo(null);
                                  await fetchProducts(
                                    searchTerm,
                                    false,
                                    null,
                                    null
                                  );
                                },
                              },
                              {
                                content: "Review changes",
                                suffix: jobInfo?.state === "COMPLETE" && (
                                  <Badge tone="attention">
                                    {jobInfo?.rendersToPublishCount +
                                      jobInfo?.rendersToUnpublishCount}
                                  </Badge>
                                ),
                                active: onlyShowProductsWithUnpublishedChanges,
                                onAction: async () => {
                                  setOnlyShowProductsWithUnpublishedChanges(
                                    true
                                  );
                                  setViewModePopoverActive(false);

                                  setProducts(null);
                                  setPageInfo(null);
                                  await fetchProducts(
                                    searchTerm,
                                    true,
                                    null,
                                    null
                                  );
                                },
                              },
                            ]}
                          />
                        </Popover>
                      </Stack.Item>
                    }
                    {onlyShowProductsWithUnpublishedChanges && (
                      <Stack.Item fill></Stack.Item>
                    )}
                    {onlyShowProductsWithUnpublishedChanges && (
                      <Stack.Item>
                        {jobInfo?.state === "IN_PROGRESS" && (
                          <Text as="span" tone="subdued">
                            {jobInfo?.productsProcessedCount} of{" "}
                            {jobInfo?.productsToProcessCount} products processed
                          </Text>
                        )}
                        {jobInfo?.state === "COMPLETE" &&
                          jobInfo?.rendersToPublishCount != null &&
                          jobInfo?.rendersToUnpublishCount != null && (
                            <Text as="span" tone="subdued">
                              Adding {jobInfo?.rendersToPublishCount} mockup
                              {jobInfo?.rendersToPublishCount !== 1 && "s"},
                              removing {jobInfo?.rendersToUnpublishCount} mockup
                              {jobInfo?.rendersToUnpublishCount !== 1 && "s"}
                            </Text>
                          )}
                        {jobInfo?.state === "PUBLISHED" &&
                          (jobInfo?.rendersToPublishCount > 0 ||
                            jobInfo?.rendersToUnpublishCount > 0) && (
                            <Stack spacing="extraTight" alignment="center">
                              <Icon source={CheckIcon} tone="subdued" />
                              <Text as="span" tone="subdued">
                                All changes published
                              </Text>
                            </Stack>
                          )}
                      </Stack.Item>
                    )}
                    {onlyShowProductsWithUnpublishedChanges &&
                      jobInfo?.state !== "PUBLISHED" && (
                        <Stack.Item>
                          <Button
                            variant="primary"
                            disabled={
                              !(
                                onlyShowProductsWithUnpublishedChanges &&
                                jobInfo?.state === "COMPLETE"
                              )
                            }
                            onClick={async () => {
                              setIsPublishingActiveJob(true);
                              try {
                                const response = await authenticatedFetch(
                                  app,
                                  "/api/mockups/publish",
                                  {
                                    method: "POST",
                                    body: JSON.stringify({}),
                                    headers: {
                                      "content-type": "application/json",
                                      accept: "application/json",
                                    },
                                  }
                                );
                                let data = await response.json();
                                console.log(data);

                                // Manually mark job as publishing
                                // TODO: some fuzzy logic... should this state be being set locally like this?
                                setJobInfo((_jobInfo) => ({
                                  ..._jobInfo,
                                  ...{
                                    state: "PUBLISHING",
                                    productsPublishedCount: null,
                                    productsToPublishCount: null,
                                  },
                                }));
                                setIsPublishingActiveJob(true);
                              } catch (e: any) {
                                console.log("Publish failed: " + e.message);
                              }
                            }}
                            loading={isPublishingActiveJob}
                            // icon={ArrowUpMinor}
                          >
                            Publish changes
                          </Button>
                        </Stack.Item>
                      )}
                    {!onlyShowProductsWithUnpublishedChanges && (
                      <Stack.Item fill>
                        <TextField
                          label=""
                          prefix={<Icon source={SearchIcon} />}
                          placeholder="Search products by title, type or vendor"
                          autoComplete="off"
                          onChange={async (value) => {
                            // setProducts(null);
                            // setPageInfo(null);
                            setSearchTerm(value);
                            // await fetchProducts(value, null, null);

                            setProducts(null);
                            setPageInfo(null);
                            await fetchProducts(
                              value,
                              onlyShowProductsWithUnpublishedChanges,
                              null,
                              null
                            );
                          }}
                          value={searchTerm}
                          disabled={onlyShowProductsWithUnpublishedChanges}
                        ></TextField>
                      </Stack.Item>
                    )}
                  </Stack>
                  {onlyShowProductsWithUnpublishedChanges &&
                    (jobInfo?.productLimitReached ? (
                      subscriptionInfo?.productLimit > jobInfo?.productLimit ? (
                        <Banner tone="info">
                          Thanks for upgrading your subscription!{" "}
                          <Link
                            monochrome
                            onClick={() => {
                              const f = async () => {
                                // Authenticated fetch POST operation
                                const response = await authenticatedFetch(
                                  app,
                                  "/api/mockups/startJob",
                                  {
                                    method: "post",
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                  }
                                );
                                const responseJSON = await response.json();
                                console.log(responseJSON);

                                didSaveRules(); // TODO: rename function...
                              };
                              f();
                            }}
                          >
                            Re-run automation
                          </Link>{" "}
                          to process products that were skipped before.
                        </Banner>
                      ) : (
                        <Banner tone="warning">
                          Your subscription supports automated mockups on up to{" "}
                          <b>{subscriptionInfo?.productLimit} products</b>.{" "}
                          <Link
                            monochrome
                            onClick={() => {
                              setManageSubscriptionPageActive(true);
                            }}
                          >
                            Upgrade to work with more products
                          </Link>
                        </Banner>
                      )
                    ) : (
                      jobInfo?.remarks?.skippedProductsCount > 0 && (
                        <Banner tone="info">
                          {jobInfo?.remarks?.skippedProductsCount} product
                          {jobInfo?.remarks?.skippedProductsCount !== 1
                            ? "s were"
                            : " was"}{" "}
                          skipped from automation.{" "}
                          <Link
                            monochrome
                            onClick={() => setSkippedProductsListActive(true)}
                          >
                            See details
                          </Link>
                        </Banner>
                      )
                    ))}
                </Stack>
              </Card.Section>
              {fetchError ? (
                <Card.Section>
                  <div
                    style={{
                      height: "611px",
                      textAlign: "center",
                      paddingTop: "2em",
                    }}
                  >
                    <Text as="span" tone="subdued">
                      Something went wrong.{" "}
                      <Button
                        variant="plain"
                        onClick={async () =>
                          await fetchProducts(
                            searchTerm,
                            onlyShowProductsWithUnpublishedChanges,
                            null,
                            null
                          )
                        }
                      >
                        Try again
                      </Button>
                      .
                    </Text>
                  </div>
                </Card.Section>
              ) : products ? (
                products.length > 0 ? (
                  products.map((product, i) => {
                    return (
                      <ProductCell
                        product={product}
                        key={i}
                        // redirect={redirect}
                        app={app}
                        onlyShowProductsWithUnpublishedChanges={
                          onlyShowProductsWithUnpublishedChanges
                        }
                        setMockupEditorActiveProduct={
                          setMockupEditorActiveProduct
                        }
                      />
                    );
                  })
                ) : (
                  <Card.Section subdued>
                    <div
                      style={{
                        height: "611px",
                        textAlign: "center",
                        paddingTop: "2em",
                      }}
                    >
                      {!onlyShowProductsWithUnpublishedChanges &&
                        (searchTerm === "" ? (
                          <>
                            <Text as="span" tone="subdued">
                              Your store has no products.
                            </Text>{" "}
                            <Link
                              monochrome
                              url="https://help.shopify.com/en/manual/products"
                            >
                              Learn more about products on Shopify
                            </Link>
                          </>
                        ) : (
                          <div
                            style={{
                              fontSize: "14pt",
                              fontWeight: "400",
                              color: "#666",
                            }}
                          >
                            No results found for "<b>{searchTerm}</b>"
                          </div>
                        ))}
                      {onlyShowProductsWithUnpublishedChanges && (
                        <>
                          <br />
                          <br />
                          <Stack>
                            <Stack.Item fill></Stack.Item>
                            {/* <Icon source={StatusActiveMajor} color="subdued" /> */}

                            <Text as="span" tone="subdued">
                              No products with automatic mockups.{" "}
                              <Button
                                variant="plain"
                                onClick={() => setEditRulesPageActive(true)}
                              >
                                Set up Mockup Automation
                              </Button>
                              .
                            </Text>
                            <Stack.Item fill></Stack.Item>
                          </Stack>
                          <br />
                          <br />
                        </>
                      )}
                    </div>
                  </Card.Section>
                )
              ) : isLoading ? (
                [0, 1, 2, 3].map((item, i) => (
                  <ProductCell
                    product={null}
                    key={i}
                    app
                    // redirect={redirect}
                    onlyShowProductsWithUnpublishedChanges
                    setMockupEditorActiveProduct
                  />
                ))
              ) : (
                <Card.Section>
                  <br />
                  <br />
                  <Stack distribution="center">
                    <div
                      style={{
                        fontSize: "14pt",
                        fontWeight: "400",
                        color: "#666",
                      }}
                    >
                      Search or{" "}
                      <Link
                        onClick={async () => {
                          setProducts(null);
                          setPageInfo(null);
                          setSearchTerm("");
                          await fetchProducts(
                            "",
                            onlyShowProductsWithUnpublishedChanges,
                            null,
                            null
                          );
                        }}
                      >
                        show all products
                      </Link>{" "}
                      to review your product media
                    </div>
                  </Stack>
                  <br />
                  <br />
                </Card.Section>
              )}
              <Card.Section>
                <Stack distribution="center">
                  <Pagination
                    hasPrevious={pageInfo?.hasPreviousPage}
                    onPrevious={async () => {
                      const startCursor = pageInfo.startCursor;
                      setProducts(null);
                      setPageInfo(null);
                      await fetchProducts(
                        searchTerm,
                        onlyShowProductsWithUnpublishedChanges,
                        null,
                        startCursor
                      );
                    }}
                    hasNext={pageInfo?.hasNextPage}
                    onNext={async () => {
                      const endCursor = pageInfo.endCursor;
                      setProducts(null);
                      setPageInfo(null);
                      await fetchProducts(
                        searchTerm,
                        onlyShowProductsWithUnpublishedChanges,
                        endCursor,
                        null
                      );
                    }}
                  />
                </Stack>
              </Card.Section>
            </Card>
            <br />
            <br />
            <br />
            <br />
            <br />
          </Page>
        ) : (
          <>
            {editRulesPageActive && !manageSubscriptionPageActive && (
              <EditRulesPage
                app={app}
                subscriptionInfo={subscriptionInfo}
                setEditRulesPageActive={setEditRulesPageActive}
                setManageSubscriptionPageActive={
                  setManageSubscriptionPageActive
                }
                didSaveRules={didSaveRules}
                betaMode={betaMode}
              />
            )}
            {manageSubscriptionPageActive && (
              <ManageSubscriptionPage
                app={app}
                setManageSubscriptionPageActive={
                  setManageSubscriptionPageActive
                }
                setSubscriptionInfo={setSubscriptionInfo}
              />
            )}
          </>
        )}
      </Page>

      {learnAboutAutomationModalActive && (
        <LearnAboutAutomationModal
          setLearnAboutAutomationModalActive={
            setLearnAboutAutomationModalActive
          }
          setEditRulesPageActive={setEditRulesPageActive}
        />
      )}

      <Modal
        open={skippedProductsListActive}
        title="Skipped Products"
        onClose={() => setSkippedProductsListActive(false)}
      >
        <SkippedProductsList
          app={app}
          setEditRulesPageActive={setEditRulesPageActive}
          setSkippedProductsListActive={setSkippedProductsListActive}
        />
      </Modal>

      <Modal
        open={mockupEditorActiveProduct}
        title="Hello"
        titleHidden={true}
        size="large"
        noScroll
        // instant
        onClose={function (): void {
          setMockupEditorActiveProduct(null);
        }}
      >
        <Suspense
          fallback={
            <div
              style={{
                height: "40em",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          }
        >
          <MockupEditor
            app={app}
            shop={shop}
            product={mockupEditorActiveProduct}
            setMockupEditorActiveProduct={setMockupEditorActiveProduct}
            imagePublishedCallback={(product, publishedImageURL) => {
              // Show success toast
              setPublishSuccessToastActive(true);

              // Add the newly published image to the thumbnails
              const newProducts = products.map((p) => {
                if (product.id === p.id) {
                  return {
                    ...p,
                    images: [
                      ...product.images,
                      {
                        transformedSrc: publishedImageURL,
                        originalSrc: publishedImageURL,
                      },
                    ],
                  };
                }
                return p;
              });
              setProducts(newProducts);
            }}
            inputField={inputField}
            setInputField={setInputField}
            isInches={isInches}
            betaMode={betaMode}
            watermark={!subscriptionInfo?.removeWatermark}
            manageSubscriptionCallback={() => {
              setMockupEditorActiveProduct(null);
              setManageSubscriptionPageActive(true);
            }}
            template={undefined}
            setMockupEditorActiveTemplate={undefined}
            saveTemplateCallback={undefined}
            deleteTemplateCallback={undefined}
          />
        </Suspense>
      </Modal>
    </Frame>
    // </AliveScope>
  );
}

export default ProductImageReviewer;
