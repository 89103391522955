/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

import "@shopify/polaris/build/esm/styles.css";
import { Banner, LegacyStack as Stack } from "@shopify/polaris";

function ScenePanel({
  inputsHandler,
  inputField,
  scenes,
  customScenes,
  automaticSceneSelection,
  setAutomaticSceneSelection,
  app,
  watermark,
  betaMode,
  manageSubscriptionCallback,
}) {
  // const handleChange = useCallback(
  //   (newChecked) => setAutomaticSceneSelection(newChecked),
  //   [setAutomaticSceneSelection]
  // );

  // const redirect = app && Redirect.create(app);
  // const [postingTier, setPostingTier] = useState<any>(null);
  // const selectTier = async (tier) => {
  //   console.log(`selectTier: ${tier}`);

  //   try {
  //     setPostingTier(tier);

  //     // Update product on database with the new image URL
  //     const responseRaw = await authenticatedFetch(
  //       app,
  //       `/api/settings/selectSubscription`,
  //       {
  //         method: "POST",
  //         body: JSON.stringify({ tier: tier }),
  //         headers: {
  //           "content-type": "application/json",
  //           accept: "application/json",
  //         },
  //       }
  //     );
  //     const response = await responseRaw.json();
  //     console.log("selectTier response: " + JSON.stringify(response));

  //     if (response.result === "success") {
  //       // getStatus();
  //       console.log(`Should be redirecting to: ${response.confirmationUrl}`);
  //       redirect.dispatch(Redirect.Action.REMOTE, response.confirmationUrl);
  //     } else {
  //       setPostingTier(null);
  //       // setPostingFailed(true);
  //     }
  //   } catch (error) {
  //     setPostingTier(null);
  //     // setPostingFailed(true);
  //   }
  // };

  return (
    <>
      <Stack vertical>
        {watermark && (
          <Banner
            // title="Watermark"
            action={{
              content: "Manage Subscription",
              // loading: postingTier,
              // onAction: () => selectTier("Automate 500"),
              onAction: () => manageSubscriptionCallback(),
            }}
          >
            Upgrade your subscription to remove the <i>frameup.app</i> watermark
          </Banner>
        )}
        {customScenes.length > 0 && (
          <>
            <Stack spacing="tight">
              {customScenes.map((scene, index) => (
                <a
                  key={scene.id}
                  id={scene.id}
                  onClick={(e) => {
                    if (betaMode && e.metaKey) {
                      // If cmd-click, allow multiple scene selection
                      if (inputField.sceneIndices.includes(scene.id)) {
                        // Remove scene
                        inputsHandler(
                          inputField.sceneIndices.filter(
                            (item) => item !== scene.id
                          ),
                          "sceneIndices"
                        );
                      } else {
                        // Add scene
                        inputsHandler(
                          [...inputField.sceneIndices, scene.id],
                          "sceneIndices"
                        );
                      }
                    } else {
                      // Replace any scenes with just the clicked scene
                      inputsHandler([scene.id], "sceneIndices");
                    }
                  }}
                >
                  <img
                    src={scene.backgroundThumbnail}
                    style={{
                      width: 73,
                      height: 73,
                      borderWidth: 3,
                      borderStyle: inputField.sceneIndices?.includes(scene.id)
                        ? "solid"
                        : undefined,
                      borderColor: inputField.sceneIndices?.includes(scene.id)
                        ? "#0072F4"
                        : undefined,
                      borderRadius: "var(--p-border-width-050)",
                      backgroundColor: "var(--p-color-bg-surface-brand)",
                    }}
                    alt=""
                  />
                </a>
              ))}
            </Stack>
            <hr
              className="solid"
              style={{
                display: "block",
                height: "1px",
                border: 0,
                borderTop: "1px solid #ccc",
                margin: "1.5em -2em 0.8em -2em",
                padding: 0,
              }}
            ></hr>
          </>
        )}
        <Stack spacing="tight">
          {scenes.map((scene, index) => (
            <a
              key={scene.id}
              id={scene.id}
              onClick={(e) => {
                if (betaMode && e.metaKey) {
                  // If cmd-click, allow multiple scene selection
                  if (inputField.sceneIndices.includes(scene.id)) {
                    // Remove scene
                    inputsHandler(
                      inputField.sceneIndices.filter(
                        (item) => item !== scene.id
                      ),
                      "sceneIndices"
                    );
                  } else {
                    // Add scene
                    inputsHandler(
                      [...inputField.sceneIndices, scene.id],
                      "sceneIndices"
                    );
                  }
                } else {
                  // Replace any scenes with just the clicked scene
                  inputsHandler([scene.id], "sceneIndices");
                }
              }}
            >
              <img
                src={scene.backgroundThumbnail}
                style={{
                  width: 73,
                  height: 73,
                  borderWidth: 3,
                  borderStyle: inputField.sceneIndices?.includes(scene.id)
                    ? "solid"
                    : undefined,
                  borderColor: inputField.sceneIndices?.includes(scene.id)
                    ? "#0072F4"
                    : undefined,
                  borderRadius: "var(--p-border-width-050)",
                  backgroundColor: "var(--p-color-bg-surface-brand)",
                }}
                alt=""
              />
            </a>
          ))}
        </Stack>
        {/* <Banner status="info" title="Looking for more scenes?">
          <Link url="mailto:support@frameup.app">Contact us</Link> to enquire
          about custom scenes available to your store only.
        </Banner> */}
      </Stack>
    </>
  );
}

export default ScenePanel;
