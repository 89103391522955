import { Vector3 } from "three";

export function roughlyEquals(
  a: number,
  b: number,
  threshold?: number
): boolean;
export function roughlyEquals(
  a: Vector3,
  b: Vector3,
  threshold?: number
): boolean;

export function roughlyEquals(
  a: any,
  b: any,
  threshold: number = 0.0001
): boolean {
  if (typeof a === "number" && typeof b === "number") {
    return Math.abs(a - b) < threshold;
  } else if (a instanceof Vector3 && b instanceof Vector3) {
    return (
      Math.abs(a.x - b.x) < threshold &&
      Math.abs(a.y - b.y) < threshold &&
      Math.abs(a.z - b.z) < threshold
    );
  }
  throw new Error("Invalid arguments");
}
