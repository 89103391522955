import { Button, Icon, Select, TextField } from "@shopify/polaris";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  DragHandleIcon,
} from "@shopify/polaris-icons";
import React, { ReactNode, useState, useMemo } from "react";
import Collapsible from "react-collapsible";
import { List } from "react-movable";
import { SubSectionHeading } from "./Text.tsx";
import StyleSection from "../StyleSection.tsx";
import { RuleType, ESRule } from "../model/ESConfig.ts";

interface RulesTableProps {
  // children: ReactNode[];
  cells: RulesCellProps[];
  // modifyEsRule: (newRule: ESRule) => void;
  modifyEsRuleOrder: (oldIndex, newIndex) => void;
  newEsRule: () => void;
}

interface RulesCellProps {
  rule: ESRule;
  modifyEsRule: (ruleId: string, key: string, value: any) => void;
  modifyEsRuleArtworkParameter: (
    ruleId: string,
    key: string,
    value: any
  ) => void;
  title: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  content?: ReactNode;
  ruleId: string;
  itemsOpenState?: any;
  setItemsOpenState?: any;
  duplicateEsRule: (ruleId: string) => void;
  deleteEsRule: (ruleId: string) => void;
}

function RulesTable(props: RulesTableProps) {
  const [cellsOpenState, setCellsOpenState] = useState<{
    [id: string]: boolean;
  }>({});

  return (
    <div className="border border-gray-200 rounded-lg overflow-clip w-full">
      {/* {JSON.stringify(props.cells)} */}
      <List
        values={props.cells}
        onChange={({ oldIndex, newIndex }) =>
          // setCells(arrayMove(props.cells, oldIndex, newIndex))
          props.modifyEsRuleOrder(oldIndex, newIndex)
        }
        renderList={({ children, props, isDragged }) => (
          <div
            {...props}
            // style={{
            //   cursor: isDragged ? "grabbing" : "inherit",
            // }}
          >
            {children}
          </div>
        )}
        renderItem={({ value, props, isDragged }) => (
          <div
            {...props}
            key={props.key}
            className={
              (isDragged ? "shadow-xl rounded-lg overflow-clip border-t" : "") +
              " group"
            }
            // className={isDragged ? "bg-green-50" : "bg-red-50"}
            // style={
            //   {
            //     // cursor: isDragged ? "grabbing" : "grab",
            //     // backgroundColor: isDragged ? "#EEE" : "#FFF",
            //   }
            // }
          >
            <RulesCell
              {...value}
              itemsOpenState={cellsOpenState}
              setItemsOpenState={setCellsOpenState}
            />
          </div>
        )}
        lockVertically
      />
      <div className="border-t p-4 flex flex-row justify-center">
        <Button variant="secondary" onClick={() => props.newEsRule()}>
          New variant rule
        </Button>
      </div>
    </div>
  );
}

function RulesCell(props: RulesCellProps) {
  const ruleId = props.ruleId;
  const open = useMemo(
    () => props.itemsOpenState[props.ruleId],
    [props.itemsOpenState, props.ruleId]
  );

  return (
    <div className="border-b border-gray-200 bg-white group-last:border-b-0">
      <Collapsible
        transitionTime={120}
        open={props.itemsOpenState[props.ruleId]}
        onTriggerOpening={() =>
          props.setItemsOpenState((s) => ({ ...s, [ruleId]: true }))
        }
        onTriggerClosing={() =>
          props.setItemsOpenState((s) => ({ ...s, [ruleId]: false }))
        }
        easing="ease-in-out"
        trigger={
          <div className="flex flex-row p-3 justify-between cursor-pointer select-none">
            <div className="flex flex-row flex-grow-0 overflow-hidden justify-start space-x-2">
              <div className="h-6 pt-1">
                {open ? (
                  <Icon source={ChevronDownIcon} />
                ) : (
                  <Icon source={ChevronRightIcon} />
                )}
              </div>
              <div className="text-ellipsis overflow-hidden pt-1">
                {props.title}
              </div>
            </div>
            <div className="">
              <button
                data-movable-handle
                className="cursor-grab h-6 pt-1"
                style={{
                  cursor: "grab",
                }}
              >
                <Icon source={DragHandleIcon} />
              </button>
            </div>
          </div>
        }
      >
        <div className="border-t border-gray-200 bg-gray-100 p-3">
          {/* {props.content} */}
          <RulesCellContent {...props} />
        </div>
      </Collapsible>
    </div>
  );
}

function RulesCellContent(props: RulesCellProps) {
  // This is just for the artwork fields
  const inputField: any = useMemo(() => {
    return {
      ...props.rule.artworkConfig,
      style: props.rule.artworkConfig?.kind ?? "default",
      frameMounted: (props.rule.artworkConfig as any)?.isMounted,
    };
  }, [props.rule.artworkConfig]);
  const inputsHandler = (value, fieldId) => {
    let strippedFieldId = (fieldId as string).split("/")[1];

    // Remap weird input names from old frontend code
    if (strippedFieldId === "style") {
      strippedFieldId = "kind";
    } else if (strippedFieldId === "frameMounted") {
      strippedFieldId = "isMounted";
    }
    props.modifyEsRuleArtworkParameter(props.rule.id, strippedFieldId, value);
  };

  return (
    <div className="flex flex-col space-y-5">
      {/* {props.rule.variantOptionName} */}
      <div className="flex flex-col space-y-2 mt-2">
        <SubSectionHeading>If variant option...</SubSectionHeading>
        <div>
          <TextField
            label=""
            value={props.rule.variantOptionName}
            placeholder="Type a variant option name..."
            onChange={(value) => {
              props.modifyEsRule(props.rule.id, "variantOptionName", value);
            }}
            autoComplete="off"
          />
        </div>
        <div className="w-1/2">
          <Select
            options={[
              {
                label: "Has exact value...",
                value: RuleType.EXACT_MATCH,
              },
              { label: "Apply artwork size", value: RuleType.ARTWORK_SIZE },
            ]}
            onChange={(value) => {
              props.modifyEsRule(props.rule.id, "ruleType", value);
            }}
            value={props.rule.ruleType}
            label={undefined}
          />
        </div>
        {props.rule.ruleType === RuleType.EXACT_MATCH && (
          <TextField
            label=""
            value={props.rule.term}
            placeholder="Type a variant option name..."
            onChange={(value) => {
              props.modifyEsRule(props.rule.id, "term", value);
            }}
            autoComplete="off"
          />
        )}
      </div>

      <div className="flex flex-col space-y-2 mt-2">
        {props.rule.ruleType === RuleType.EXACT_MATCH && (
          <>
            <SubSectionHeading>Apply styles...</SubSectionHeading>
            <StyleSection
              inputsHandler={inputsHandler}
              inputField={inputField}
              showTitle={false}
              formElementPrefix={props.rule.id + "/"}
              showAllOptionsAndAllowNull={true}
            />
          </>
        )}
        {/* <div className="flex flex-row space-x-2">
          <div className="w-1/2">
            <Select
              options={[
                { label: "Framed", value: "exact" },
                { label: "Apply artwork size", value: "size" },
              ]}
              onChange={() => {}}
              value="exact"
              label="Framing"
            />
          </div>
          <div className="w-1/2">
            <Select
              options={[
                { label: "Natural Grain", value: "exact" },
                { label: "Apply artwork size", value: "size" },
              ]}
              onChange={() => {}}
              value="exact"
              label="Frame Material"
            />
          </div>
        </div> */}
        {/* <Checkbox checked label="Mounted" /> */}
        <div className="flex flex-row justify-end space-x-1.5">
          <Button
            variant="secondary"
            onClick={() => props.duplicateEsRule(props.rule.id)}
          >
            Duplicate
          </Button>
          <Button
            variant="secondary"
            tone="critical"
            onClick={() => props.deleteEsRule(props.rule.id)}
          >
            Delete
          </Button>
        </div>
        {/* {JSON.stringify(inputField)} */}
      </div>
    </div>
  );
}

export { RulesTable, RulesCell };
