import React from "react";
import {
  Button,
  Combobox,
  Listbox,
  Icon,
  Checkbox,
  RadioButton,
  TextField,
  Spinner,
} from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import { SaveBar } from "@shopify/app-bridge-react";
import { useState, useCallback, useMemo, useEffect } from "react";
import { Configuration, Starboard } from "starboard";
import { PresentationMode } from "starboard";
import {
  AreaHeading,
  PageHeading,
  SectionHeading,
  SubSectionHeading,
} from "./components/Text.tsx";
import { SectionSeparator } from "./components/Separator.tsx";
import { RulesTable } from "./components/RulesTable.tsx";
import StyleSection from "./StyleSection.tsx";
import {
  ESConfig,
  ArtworkImageSource,
  RuleType,
  ESRule,
} from "./model/ESConfig.ts";
import { arrayMove } from "react-movable";

//@ts-ignore
import { authenticatedFetch } from "./APIUtils.ts";

import deepCompare from "./helpers/deepCompare.ts";

function ComboboxExample() {
  const deselectedOptions = useMemo(
    () => [
      { value: "rustic", label: "Rustic" },
      { value: "antique", label: "Antique" },
      { value: "vinyl", label: "Vinyl" },
      { value: "vintage", label: "Vintage" },
      { value: "refurbished", label: "Refurbished" },
    ],
    []
  );

  const [selectedOption, setSelectedOption] = useState<string | undefined>();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(deselectedOptions);

  const escapeSpecialRegExCharacters = useCallback(
    (value: string) => value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
    []
  );

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);

      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(escapeSpecialRegExCharacters(value), "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions, escapeSpecialRegExCharacters]
  );

  const updateSelection = useCallback(
    (selected: string) => {
      const matchedOption = options.find((option) => {
        return option.value.match(selected);
      });

      setSelectedOption(selected);
      setInputValue((matchedOption && matchedOption.label) || "");
    },
    [options]
  );

  const optionsMarkup =
    options.length > 0
      ? options.map((option) => {
          const { label, value } = option;

          return (
            <Listbox.Option
              key={`${value}`}
              value={value}
              selected={selectedOption === value}
              accessibilityLabel={label}
            >
              {label}
            </Listbox.Option>
          );
        })
      : null;

  return (
    <div>
      <Combobox
        activator={
          <Combobox.TextField
            prefix={<Icon source={SearchIcon} />}
            onChange={updateText}
            label="Example Product"
            labelHidden
            value={inputValue}
            placeholder="Example Product"
            autoComplete="off"
          />
        }
      >
        {options.length > 0 ? (
          <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
        ) : null}
      </Combobox>
    </div>
  );
}

function AppEmbed({ app }) {
  const [unsavedEsConfig, setUnsavedEsConfig] = useState<ESConfig | null>();
  const [esConfig, setEsConfig] = useState<ESConfig | null>();

  const [shopify, setShopify] = useState<any>();

  useEffect(() => {
    loadConfig();

    setShopify(window["shopify"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deepCompare(unsavedEsConfig, esConfig)) {
      shopify?.saveBar.hide("my-save-bar");
    } else {
      shopify?.saveBar.show("my-save-bar");
    }
  }, [unsavedEsConfig, esConfig, shopify]);

  const modifyEsConfig = (value, fieldId) => {
    // const result = keypath.split('.').reduce((previous, current) => previous[current], target);

    setUnsavedEsConfig((prevEsConfig) => ({
      ...prevEsConfig,
      [fieldId]: value,
    }));
  };

  const modifyEsRuleOrder = (oldIndex, newIndex) => {
    setUnsavedEsConfig((prevEsConfig) => ({
      ...prevEsConfig,
      rules: arrayMove(prevEsConfig.rules, oldIndex, newIndex),
    }));
  };

  const newEsRule = () => {
    setUnsavedEsConfig((prevEsConfig) => ({
      ...prevEsConfig,
      rules: [
        ...prevEsConfig.rules,
        {
          id: crypto.randomUUID(),
          variantOptionName: "Example option name",
          ruleType: RuleType.EXACT_MATCH,
          term: "Example option value",
          artworkConfig: {
            kind: "default",
            edgeFinish: "default",
            frameMaterial: "default",
          } as any,
        } as ESRule,
      ],
    }));
  };
  const duplicateEsRule = (ruleId: string) => {
    setUnsavedEsConfig((prevEsConfig) => {
      let rule = prevEsConfig.rules.find((r) => r.id === ruleId);
      if (rule) {
        return {
          ...prevEsConfig,
          rules: [
            ...prevEsConfig.rules,
            {
              ...rule,
              id: crypto.randomUUID(),
            } as ESRule,
          ],
        };
      }
      return prevEsConfig;
    });
  };
  const deleteEsRule = (ruleId: string) => {
    setUnsavedEsConfig((prevEsConfig) => ({
      ...prevEsConfig,
      rules: prevEsConfig.rules.filter((r) => r.id !== ruleId),
    }));
  };

  const modifyEsRule = (ruleId: string, key: string, value: any) => {
    setUnsavedEsConfig((prevEsConfig) => {
      let newRules = prevEsConfig.rules.map((r) => {
        if (r.id === ruleId) {
          return {
            ...r,
            [key]: value,
          };
        } else {
          return r;
        }
      });

      return {
        ...prevEsConfig,
        rules: newRules,
      };
    });
    return;
  };

  const modifyEsRuleArtworkParameter = (
    ruleId: string,
    key: string,
    value: any
  ) => {
    setUnsavedEsConfig((prevEsConfig) => {
      let newRules = prevEsConfig.rules.map((r) => {
        if (r.id === ruleId) {
          return {
            ...r,
            artworkConfig: {
              ...r.artworkConfig,
              [key]: value,
            },
          };
        } else {
          return r;
        }
      });

      return {
        ...prevEsConfig,
        rules: newRules,
      };
    });
    return;
  };

  const inputField: any = useMemo(() => {
    return {
      ...unsavedEsConfig?.defaultArtworkConfig,
      style: unsavedEsConfig?.defaultArtworkConfig.kind,
      frameMounted: (unsavedEsConfig?.defaultArtworkConfig as any)?.isMounted,
    };
  }, [unsavedEsConfig]);

  const config: Configuration = useMemo(() => {
    // console.log("inputField:");
    // console.log(inputField);
    let scene = {
      id: "dw-scene-02c_004",
      cameraFov: 39.5978,
      focalColor: "#DACCBC",
      cameraPosition: {
        x: 0.9803496599197388,
        y: 1.1669756174087524,
        z: 0.4367722570896149,
      },
      cameraRotation: {
        x: -0.0000906325294636,
        y: 1.5734152793884277,
        z: -0.0000912726027309,
      },
      artworkPosition: {
        x: -4.417304039001465,
        y: 1.6244051456451416,
        z: 0.608400821685791,
      },
      artworkRotation: { x: 0, y: 1.570796251296997, z: 0 },
      backgroundTexture:
        "https://staging.starboard.frameup.app/textures/dw-scene-02c_004.jpg",
      backgroundThumbnail: "textures/dw-scene-02c_004_thumbnail.jpg",
    } as any;

    // console.log("artwork:");
    // console.log(artwork);

    // console.log("esConfig.defaultArtworkConfig:");
    // console.log(esConfig.defaultArtworkConfig);

    const presentationMode = PresentationMode.CloseUp;

    return {
      scene: scene,
      artwork: unsavedEsConfig?.defaultArtworkConfig,
      ultraHighResolution: false,
      renderSize: { width: 1024, height: 1024 } as any,
      presentationMode: presentationMode,
    };
  }, [unsavedEsConfig]);

  const inputsHandler = (value, fieldId) => {
    // Remap weird input names from old frontend code
    if (fieldId === "style") {
      fieldId = "kind";
    } else if (fieldId === "frameMounted") {
      fieldId = "isMounted";
    }

    // Update default artwork config
    setUnsavedEsConfig((prevEsConfig) => ({
      ...prevEsConfig,
      defaultArtworkConfig: {
        ...prevEsConfig.defaultArtworkConfig,
        [fieldId]: value,
      },
    }));
  };

  /*

  - continue renaming rules to ruleCellProps and then access internal .rule property... then continue to debug why cells are re-rendering

  */

  const ruleCellProps = useMemo(() => {
    return unsavedEsConfig?.rules.map((rule) => {
      return {
        rule: rule,
        ruleId: rule.id,
        modifyEsRule: modifyEsRule,
        modifyEsRuleArtworkParameter: modifyEsRuleArtworkParameter,
        duplicateEsRule: duplicateEsRule,
        deleteEsRule: deleteEsRule,
        title: (
          <div className="text-ellipsis">
            If <b>{rule.variantOptionName}</b>
            {rule.ruleType === RuleType.EXACT_MATCH && (
              <>
                {" "}
                is exactly <b>{rule.term}</b>...
              </>
            )}
            {rule.ruleType === RuleType.ARTWORK_SIZE && (
              <>
                {" "}
                then <b>Apply artwork size</b>
              </>
            )}
          </div>
        ),
        // content: <>If variant option...</>,
      };
    });
  }, [unsavedEsConfig]);

  const loadConfig = async () => {
    // Authenticated fetch POST operation
    const response = await authenticatedFetch(app, "/api/appEmbed/config", {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    });
    console.log(response);
    const data = await response.json();
    console.log(data);

    let appEmbedConfig = data.appEmbedConfig;

    if (!appEmbedConfig || !appEmbedConfig.defaultArtworkConfig) {
      appEmbedConfig = {
        allProducts: false,
        productsWithType: {
          enabled: false,
          types: "",
        },
        artworkImageSource: ArtworkImageSource.FIRST_IMAGE,
        defaultArtworkConfig: {
          kind: "framed",
          size: { x: 0.35, y: 0.5 },
          imagePath:
            "https://free-illustration.myshopify.com/cdn/shop/products/BurningBright_d4907291-4f40-4293-808a-50b49a8239ec.jpg?v=1677248699",
          isMounted: false,
          isBordered: false,
          edgeFinish: "black",
          contentMode: "aspectScaleToFill",
          frameMaterial: "natural",
        } as any,
        rules: [],
      };
    }

    setUnsavedEsConfig(appEmbedConfig);
    setEsConfig(appEmbedConfig);

    shopify?.saveBar.hide("my-save-bar");
  };

  //
  // Saving rules
  //
  const [isSavingConfig, setIsSavingConfig] = useState(false);
  const saveConfig = async () => {
    // Set loading state
    setIsSavingConfig(true);

    // // Prepare rules to submit
    // const rulesJSON = {
    //   automationEnabled: false, // TODO: remove this entirely I guess?
    //   collections: selectedCollections,
    // };

    // Authenticated fetch POST operation
    const response = await authenticatedFetch(app, "/api/appEmbed/config", {
      method: "post",
      body: JSON.stringify(unsavedEsConfig),
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(response);
    const data = await response.json();
    console.log(data);

    setEsConfig(data.appEmbedConfig);

    // Clear loading state
    setIsSavingConfig(false);

    // "Did save rules" callback
    // didSaveConfig();
  };

  return (
    <div className="flex flex-col items-center">
      {shopify ? (
        <SaveBar id="my-save-bar">
          <button
            variant="primary"
            onClick={() => saveConfig()}
            loading={isSavingConfig ? "" : null}
          ></button>
          <button
            onClick={() => {
              setUnsavedEsConfig(esConfig);
            }}
          ></button>
        </SaveBar>
      ) : (
        <>
          <Button
            variant="primary"
            onClick={() => saveConfig()}
            loading={isSavingConfig ? "" : null}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setUnsavedEsConfig(esConfig);
            }}
          >
            Discard
          </Button>
        </>
      )}
      <div className="bg-white mx-20 mt-10 mb-10 rounded-xl border-zinc-300 border px-10 py-9 w-11/12 max-w-5xl">
        <PageHeading className="mb-6">3D Art Visualizer (beta)</PageHeading>
        {/* <Button
          onClick={() => {
            saveConfig();
          }}
          loading={isSavingConfig}
        >
          Post config
        </Button>
        <Button
          onClick={() => {
            loadConfig();
          }}
        >
          Load config
        </Button>
        <Button
          onClick={() => {
            setUnsavedEsConfig(esConfig);
          }}
          loading={isSavingConfig}
        >
          Discard config
        </Button>
        <Button onClick={() => shopify.saveBar.show("my-save-bar")}>
          Show Save Bar
        </Button> */}
        <div className="bg-cyan-50 p-5 -mx-5 rounded-xl flex items-center justify-between space-x-8 mb-10">
          <div className="space-y-1">
            <SectionHeading>
              Bring your store to life with 3D visualization
            </SectionHeading>
            <div className="text-balance">
              Add 3D visualization to your product pages by enabling Frame Up in
              the theme editor.
            </div>
          </div>
          <div className="shrink-0 space-x-2">
            <Button variant="tertiary" size="large">
              Learn more
            </Button>
            <Button variant="primary" size="large">
              Enable theme app
            </Button>
          </div>
        </div>
        {unsavedEsConfig ? (
          <div className="flex justify-between">
            <div className="pr-10 w-1/2">
              <AreaHeading className="mb-4">Configure</AreaHeading>
              <div>
                <div>
                  <SectionHeading className="mb-1">Products</SectionHeading>
                  <div className="mb-1">
                    Enable 3D Visualizer for these products:
                  </div>
                </div>
                <div className="flex flex-col">
                  <Checkbox
                    label="All products"
                    checked={unsavedEsConfig.allProducts}
                    onChange={(state) => modifyEsConfig(state, "allProducts")}
                  />
                  <Checkbox
                    label="Products with type:"
                    checked={unsavedEsConfig.productsWithType.enabled}
                    onChange={(state) =>
                      modifyEsConfig(
                        {
                          enabled: state,
                          types: unsavedEsConfig.productsWithType.types,
                        },
                        "productsWithType"
                      )
                    }
                  />
                  <div className="ml-6">
                    <TextField
                      label=""
                      value={unsavedEsConfig.productsWithType.types}
                      placeholder="e.g. fine art prints"
                      onChange={(text) =>
                        modifyEsConfig(
                          {
                            enabled: unsavedEsConfig.productsWithType.enabled,
                            types: text,
                          },
                          "productsWithType"
                        )
                      }
                      autoComplete="off"
                      disabled={!unsavedEsConfig.productsWithType.enabled}
                      // error={
                      //   unsavedEsConfig.productsWithType.enabled &&
                      //   unsavedEsConfig.productsWithType.types.length === 0 &&
                      //   "Product type is required"
                      // }
                    />
                  </div>
                </div>
              </div>
              <SectionSeparator />
              <div>
                <div>
                  <SectionHeading className="mb-1">
                    Artwork image
                  </SectionHeading>
                  <div className="mb-1">
                    Load the product's artwork image from this source:
                  </div>
                </div>
                <div className="flex flex-col">
                  <RadioButton
                    checked={
                      unsavedEsConfig.artworkImageSource ===
                      ArtworkImageSource.FIRST_IMAGE
                    }
                    onChange={(state) => {
                      if (state) {
                        modifyEsConfig(
                          ArtworkImageSource.FIRST_IMAGE,
                          "artworkImageSource"
                        );
                      }
                    }}
                    label="Product media"
                    helpText="Use the product's first image as the artwork image"
                  />
                  <RadioButton
                    checked={
                      unsavedEsConfig.artworkImageSource ===
                      ArtworkImageSource.METAFIELD
                    }
                    onChange={(state) => {
                      if (state) {
                        modifyEsConfig(
                          ArtworkImageSource.METAFIELD,
                          "artworkImageSource"
                        );
                      }
                    }}
                    label="Metafield"
                    helpText="Use the product's 'artwork.image' metafield as the artwork image"
                  />
                </div>
              </div>
              <SectionSeparator />
              <div>
                <div>
                  <SectionHeading className="mb-1">
                    Default presentation
                  </SectionHeading>
                  <div className="mb-3">
                    Present the artwork with these size and framing options:
                  </div>
                </div>
                <div className="flex flex-col">
                  <SubSectionHeading className="mb-1">Style</SubSectionHeading>
                  <StyleSection
                    inputsHandler={inputsHandler}
                    inputField={inputField}
                    showTitle={false}
                  />
                  {/* <Select
                  label="Framing"
                  options={[
                    { label: "Frame", value: "frame" },
                    { label: "Canvas", value: "canvas" },
                    { label: "Poster", value: "poster" },
                  ]}
                  onChange={() => {}}
                  value={"frame"}
                /> */}
                </div>
              </div>
              <SectionSeparator />
              <div>
                <div>
                  <SectionHeading className="mb-1">Variants</SectionHeading>
                  <div className="mb-3">
                    Override size and frame settings based on these variant
                    rules:
                  </div>
                </div>
                {/* {ruleCellProps.map((ruleCellProps) => {
                return <>{ruleCellProps.rule.variantOptionName}</>;
              })} */}
                <RulesTable
                  modifyEsRuleOrder={modifyEsRuleOrder}
                  newEsRule={newEsRule}
                  // cells={[
                  //   {
                  //     rule: esConfig.rules[0],
                  //     modifyEsRule: function (newRule: ESRule): void {
                  //       throw new Error("Function not implemented.");
                  //     },
                  //     title: <></>,
                  //     ruleId: "",
                  //   },
                  // ]}
                  cells={ruleCellProps}
                />
              </div>
            </div>
            <div className="relative w-1/2">
              <div className="bg-gray-100 p-5 -m-5 rounded-lg sticky top-4">
                <AreaHeading className="mb-3">Preview</AreaHeading>
                <div className="mb-4">
                  <ComboboxExample />
                </div>
                <div className="max-w-[35em] max-h-[35em] bg-white border border-dashed border-gray-400">
                  <Starboard
                    config={config!}
                    captureMode={false}
                    ultraHighResolution={false}
                    allowDrag={false}
                    allowHoverToPivot={true}
                    watermarkLevel={0}
                    artworkPbrMaterials={true}
                    rootUrl="https://staging.starboard.frameup.app"
                    contextWasLostAction={async () => {
                      console.log("Goodbye Starboard");
                    }}
                    onFinishLoading={() => {
                      console.log("We're done loading");
                      // setStarboardFinishedLoading(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-row space-x-2">
            <Spinner size="small" />
            Loading...
          </div>
        )}
      </div>
      {/* {JSON.stringify(esConfig)} */}
      <br />
    </div>
    // <Page fullWidth>
    //   <br />
    //   <Layout>
    //     <Layout.Section>
    //       <Card>
    //         <Text variant="headingLg" as="h5">
    //           App embed
    //         </Text>
    //         <h1 className="text-3xl font-bold underline">Hello world!</h1>
    //       </Card>
    //     </Layout.Section>
    //   </Layout>
    // </Page>
  );
}

export default AppEmbed;
