import React, { useEffect } from "react";

import "@shopify/polaris/build/esm/styles.css";
import {
  Banner,
  Checkbox,
  Text,
  Link,
  LegacyStack as Stack,
} from "@shopify/polaris";

//@ts-ignore
import ArtworkSection from "./ArtworkSection.tsx";
//@ts-ignore
import SizeSection from "./SizeSection.tsx";
//@ts-ignore
import StyleSection from "./StyleSection.tsx";

function ProductPanel({
  inputsHandler,
  inputField,
  isInches,
  handleImageFileChange,
  artworkImage,
  croppedArtworkImage,
  setCroppedArtworkImage,
  loadingArtworkImage,
  selectedProduct,
  templateMode,
  allowLinkToVariant = false,
}) {
  useEffect(() => {
    if (!allowLinkToVariant) {
      inputsHandler(false, "linkToVariant");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowLinkToVariant]);

  return (
    <>
      <Stack vertical spacing="extraLoose">
        <Stack vertical>
          {/* {selectedProduct ? (
            <button
              // onClick={() => {
              //   setResourcePickerOpen(true);
              // }}
              style={{
                display: "flex",
                backgroundColor: "white",
                padding: 10,
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#B0B0B0",
                width: "100%",
              }}
            >
              <Stack spacing="tight" alignment="center">
                <Icon source={SearchMinor} color="subdued" />
                <Stack.Item>
                  <div style={{ color: "#787878" }}>
                    {selectedProduct.title}
                  </div>
                </Stack.Item>
              </Stack>
            </button>
          ) : (
            <Button
              primary
              fullWidth
              onClick={() => setResourcePickerOpen(true)}
            >
              Select product
            </Button>
          )} */}
          {!templateMode && (
            <ArtworkSection
              selectedProduct={selectedProduct}
              handleImageFileChange={handleImageFileChange}
              artworkImage={artworkImage}
              croppedArtworkImage={croppedArtworkImage}
              setCroppedArtworkImage={setCroppedArtworkImage}
              loadingArtworkImage={loadingArtworkImage}
            />
          )}
          {!templateMode && (
            <SizeSection
              inputsHandler={inputsHandler}
              inputField={inputField}
              isInches={isInches}
            />
          )}
          <StyleSection inputsHandler={inputsHandler} inputField={inputField} />
          {templateMode && (
            <>
              <hr
                className="solid"
                style={{
                  display: "block",
                  height: "1px",
                  border: 0,
                  borderTop: "1px solid #ccc",
                  margin: "0.3em -2em 2em -2em",
                  padding: 0,
                }}
              ></hr>
              <Text variant="headingMd" as="h2">
                Size
              </Text>
              <>
                <p>
                  Set a default artwork size in <u>Automation Settings</u>.
                </p>
                <br />
                <Checkbox
                  id="linkToVariant"
                  onChange={inputsHandler}
                  checked={inputField.linkToVariant}
                  disabled={!allowLinkToVariant}
                  label="Create a version of this mockup for each variant"
                />
                <br />
                <br />
                {inputField.linkToVariant ? (
                  <Banner tone="info">
                    A mockup will be created for each "Size" option found on the
                    product.
                    <br />
                    <br />
                    To create mockups based on frame styles please{" "}
                    <Link url="mailto:support@frameup.app">contact us</Link>.
                  </Banner>
                ) : (
                  !allowLinkToVariant && (
                    <Banner tone="warning">
                      This option can only be enabled for one mockup
                    </Banner>
                  )
                )}
              </>
            </>
          )}
          {/* <br />
          <Banner
            status="warning"
            action={{
              content: "Go to product",
              onAction: () => {
                const productIdClean = selectedProduct.id.split("/").pop();
                redirect.dispatch(Redirect.Action.ADMIN_PATH, {
                  path: `/products/${productIdClean}`,
                  newContext: true,
                });
              },
            }}
            title="Product detection is in beta"
          >
            Please check the artwork image and dimensions are as expected.
          </Banner> */}

          {/* <FooterHelp>
            Need anything?{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:contact@frameup.app"
            >
              Email us
            </a>
            !
          </FooterHelp> */}
        </Stack>
      </Stack>
    </>
  );
}

export default ProductPanel;
