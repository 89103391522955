import React from "react";

import "@shopify/polaris/build/esm/styles.css";
import { Text, FormLayout, TextField } from "@shopify/polaris";

function roundToOneDecimalPlace(number) {
  return Math.round(number * 10) / 10;
}

function SizeSection({ inputsHandler, inputField, isInches }) {
  let width = isInches
    ? roundToOneDecimalPlace(parseFloat(inputField.width) / 2.51).toString()
    : parseFloat(inputField.width).toFixed(0);
  let height = isInches
    ? roundToOneDecimalPlace(parseFloat(inputField.height) / 2.51).toString()
    : parseFloat(inputField.height).toFixed(0);

  return (
    <>
      <Text variant="headingMd" as="h2">
        Size
      </Text>
      <FormLayout>
        <FormLayout.Group condensed>
          <TextField
            id="width"
            label="Width"
            type="number"
            step={isInches ? 5 : 10}
            min={isInches ? 5 : 10}
            onChange={inputsHandler}
            value={width.toString()}
            autoComplete="off"
            suffix={isInches ? "in" : "cm"}
          />
          <TextField
            id="height"
            label="Height"
            type="number"
            step={isInches ? 5 : 10}
            min={isInches ? 5 : 10}
            onChange={inputsHandler}
            value={height.toString()}
            autoComplete="off"
            suffix={isInches ? "in" : "cm"}
          />
        </FormLayout.Group>
        {/* <Checkbox
          id="lockAspectRatio"
          label="Lock aspect ratio to image"
          onChange={inputsHandler}
          checked={inputField.lockAspectRatio}
        />
        {!inputField.lockAspectRatio && (
          <Select
            id="contentMode"
            label="Content Mode"
            onChange={inputsHandler}
            options={[
              {
                label: "Crop artwork to fill frame",
                value: ContentMode.AspectScaleToFill,
              },
              {
                label: "Stretch artwork boundary to fill frame",
                value: ContentMode.AspectScaleToFit,
              },
            ]}
            value={inputField.contentMode}
          />
        )} */}
      </FormLayout>
    </>
  );
}

export default SizeSection;
